<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de Segmentos Produtos</h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="SegmentoProd.psgId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="SegmentoProd.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-select v-model="SegmentoProd.cdtId" label="Selecione a Tela" :items="Telas"
                                                item-title="cdtNome" item-value="cdtId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="SegmentoProd.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista()"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-select v-model="SegmentoProd.psgIdPai" label="Seg Pai" :items="ListaSegmentosPai"
                                                item-title="psgNomeSegmento" item-value="psgId" variant="underlined" :clearable="true">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="SegmentoProd.psgNomeSegmento"
                                                label="Nome Segmento" variant="underlined" required></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="SegmentoProd.psgOrdem" label="Ordem" type="number"
                                                variant="underlined"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="3">
                                            <v-select v-model="SegmentoProd.psgVisivel" label="Visível" :items="StatusSegmento"
                                                item-title="nome" item-value="psgVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none;">
                                            <v-select v-model="SegmentoProd.psgDestaque" label="Destaque" :items="StatusDestaque"
                                                item-title="nome" item-value="psgDestaque" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <!--small style="padding-bottom:5px;"><strong>Texto</strong></!--small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="SegmentoProd.psgResumo" :config="$ckconfig"></!--ckeditor-->
                                            <v-text-field v-model="SegmentoProd.psgResumo" label="Nome Original" type="text"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col  cols="12" md="12" style="display:none;">
                                            <small style="padding-bottom:5px;"><strong>Descrição</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="SegmentoProd.psgDescricao1" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="12" style="display:none;">
                                            <small style="padding-bottom:5px;"><strong>Descrição 2</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="SegmentoProd.psgDescricao2" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar(true)" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="SegmentoProd.psgId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ListSegmentosProd)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                       
                        <template #item-psgVisivel="item">
                            <span>{{ item.psgVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-psgOrdem="item">
                            <span>{{ parseInt(item.psgOrdem) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
export default {
    name: 'SegmentosProdView',
    components: {
        EasyDataTable
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        Idiomas:[],
        vshowidioma: false,
        Telas:[{ cdtId: 14, cdtNome: "Produtos" }],
        SegmentoProd: {
            psgId: 0,
            grtId: 0,
            cdtId: '',
            idiId: '',
            psgNomeSegmento: '',
            psgVisivel: '',
            psgDestaque: '',
            psgOrdem: '',
            psgResumo: '',
            psgDescricao1: '',
            psgDescricao2: '',
            psgIdPai:'',
        },
        selectedVisivel: null,
        StatusSegmento: [{ nome: "Sim", psgVisivel: true }, { nome: "Não", psgVisivel: false }],
        StatusDestaque:  [{ nome: "Sim", psgDestaque: true }, { nome: "Não", psgDestaque: false }],
        headers: [],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
        ListSegmentosProd: [],
        ListSegmentosProdGeral:[],
        ListaSegmentosPai:[],
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                psgOrdem: parseInt(item.psgOrdem)
            };
            });
        },
        Limpar(value) {
            this.SegmentoProd.psgId = 0;
            this.SegmentoProd.psgNomeSegmento = '';
            this.SegmentoProd.psgOrdem = '';
            this.SegmentoProd.psgVisivel = '';
            this.SegmentoProd.psgDestaque = '';
            this.SegmentoProd.psgResumo = '';
            this.SegmentoProd.psgDescricao1 = '';
            this.SegmentoProd.psgDescricao2 = '';
            this.SegmentoProd.psgIdPai = '';
            this.selectedVisivel = '';
            if (value){
                this.SegmentoProd.grtId=0;
                this.SegmentoProd.cdtId='';
                this.SegmentoProd.idiId='';
                this.vshowidioma = false;
               this.ListSegmentosProd = [];
               this.Get();
            }
            this.tab = 'dados';
            this.$scrollToTop();
        },
        CarregaLista(){
            axios.get(`/Process/ProSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosProdGeral = response.data;
                                    this.montaGrid();
                                    this.ListaSegmentosPai = this.ListSegmentosProdGeral.filter(x=> x.psgVisivel && x.cdtId == this.SegmentoProd.cdtId && x.idiId == this.SegmentoProd.idiId);
           this.ListSegmentosProd = this.ListSegmentosProdGeral.filter(x=> x.cdtId == this.SegmentoProd.cdtId && x.idiId == this.SegmentoProd.idiId);
        //------ carrega select psgIdPai com hierarquia
        function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
            const segmentosFilhos = segmentos.filter(segmento => segmento.psgIdPai === segmentoIdPai);
            const segmentosComCaminho = [];

            for (const segmentoFilho of segmentosFilhos) {
                const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.psgNomeSegmento : `${caminhoAtual} > ${segmentoFilho.psgNomeSegmento}`;

                segmentosComCaminho.push({ psgId: segmentoFilho.psgId, psgNomeSegmento: caminhoCompleto });
                
                const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.psgId, caminhoCompleto);
                segmentosComCaminho.push(...segmentosNetos);
            }

            return segmentosComCaminho;
            }
        
            const segmentosComCaminho = [];
            for (const segmento of this.ListaSegmentosPai.filter(x=> x.psgIdPai== 0)) {
            segmentosComCaminho.push({ psgId: segmento.psgId, psgNomeSegmento: segmento.psgNomeSegmento });
            
            const segmentosFilhos = buildHierarchy(this.ListaSegmentosPai, segmento.psgId, segmento.psgNomeSegmento);
            segmentosComCaminho.push(...segmentosFilhos);
            }
            this.ListaSegmentosPai = segmentosComCaminho;   
            this.ListSegmentosProd =  this.itensComHierarquia(this.ListSegmentosProd);  
        //------
        });
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "psgId", sortable: true, });
            this.headers.push({ text: "Hierarquia", value: "Hierarquia", sortable: true});
            this.headers.push({ text: "Segmentos", value: "psgNomeSegmento", sortable: true, });
            this.headers.push({ text: "Ordem", value: "psgOrdem", sortable: true, });
            this.headers.push({ text: "Visível", value: "psgVisivel", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        itensComHierarquia(itens) {
        // Adicione a lógica para adicionar a coluna "Hierarquia" ao array de dados
        const itensComHierarquia = itens.map((item) => {
            const hierarquia = this.ListaSegmentosPai.filter(
            (x) => x.psgId === item.psgIdPai
            )[0]?.psgNomeSegmento || "";

            return { ...item, Hierarquia: hierarquia };
        });

          return itensComHierarquia;
        },
        Add() {
            if (this.SegmentoProd.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
            }
            else if (this.SegmentoProd.psgNomeSegmento == '') {
                this.$mensagemAviso("Campo Nome Segmento Obrigatório.")
            }
            else if (this.SegmentoProd.psgOrdem === ''){
                this.$mensagemAviso("Campo Ordem Obrigatório.")
            }
            else if (this.SegmentoProd.psgVisivel === ''){
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else {
                if (this.SegmentoProd.psgVisivel === "" || this.SegmentoProd.psgVisivel === null) {
                    this.SegmentoProd.psgVisivel = true;
                }
                if (this.SegmentoProd.psgDestaque === "" || this.SegmentoProd.psgDestaque === null) {
                        this.SegmentoProd.psgDestaque = false;
                }
                if (this.SegmentoProd.psgOrdem === "" || this.SegmentoProd.psgOrdem === null) {
                    this.SegmentoProd.psgOrdem = 0;
                }
                else {
                    this.SegmentoProd.psgOrdem = parseInt(this.SegmentoProd.psgOrdem);
                }
                this.SegmentoProd.admGrupoTelas = { GrtId: this.SegmentoProd.grtId }
                this.SegmentoProd.admCadastroTelas = { CdtId: this.SegmentoProd.cdtId }
                this.SegmentoProd.cadIdiomas = { IdiId: this.SegmentoProd.idiId }
                if (this.SegmentoProd.psgIdPai!='' && this.SegmentoProd.psgIdPai!=null)
                {
                    this.SegmentoProd.psgSegmentosPai = {PsgId: this.SegmentoProd.psgIdPai}
                }
                else{
                    this.SegmentoProd.psgSegmentosPai = null;
                }
                console.log('this.SegmentoProd')
                console.log(this.SegmentoProd)
                
                axios.post(`/Process/ProSegmentos/Add`, this.SegmentoProd)
                    .then(response => {
                        if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaLista();
                                this.Limpar(false);
                        }
                    });
            }
        },
        Put() {
            if (this.SegmentoProd.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
            }
            else if (this.SegmentoProd.psgNomeSegmento == '') {
                this.$mensagemAviso("Campo Nome Segmento Obrigatório.")
            }
            else if (this.SegmentoProd.psgOrdem === ''){
                this.$mensagemAviso("Campo Ordem Obrigatório.")
            }
            else if (this.SegmentoProd.psgVisivel === ''){
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else {
                if (this.SegmentoProd.psgVisivel === "" || this.SegmentoProd.psgVisivel === null) {
                    this.SegmentoProd.psgVisivel = true;
                }
                if (this.SegmentoProd.psgDestaque === "" || this.SegmentoProd.psgDestaque === null) {
                        this.SegmentoProd.psgDestaque = false;
                }
                if (this.SegmentoProd.psgOrdem === "" || this.SegmentoProd.psgOrdem === null) {
                    this.SegmentoProd.psgOrdem = 0;
                }
                else {
                    this.SegmentoProd.psgOrdem = parseInt(this.SegmentoProd.psgOrdem);
                }
                this.SegmentoProd.admGrupoTelas = { GrtId: this.SegmentoProd.grtId }
                this.SegmentoProd.admCadastroTelas = { CdtId: this.SegmentoProd.cdtId }
                this.SegmentoProd.cadIdiomas = { IdiId: this.SegmentoProd.idiId }
                if (this.SegmentoProd.psgIdPai!='' && this.SegmentoProd.psgIdPai!=null)
                {
                    this.SegmentoProd.psgSegmentosPai = {PsgId: this.SegmentoProd.psgIdPai}
                }
                else{
                    this.SegmentoProd.psgSegmentosPai = null;
                }
                //console.log('this.SegmentoProd')
                //console.log(this.SegmentoProd)
                axios.put(`/Process/ProSegmentos/Update`, this.SegmentoProd)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaLista();
                            this.Limpar(false);
                        }
                    });
            }
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item
            if (item.psgIdPai == 0){
                item.psgIdPai = null;
            }
            this.SegmentoProd = item;
            this.$scrollToTop();
        },
        DeleteGrid(item) {
           
            var validaFilhos = this.ListSegmentosProd.filter(x=> x.psgIdPai == item.psgId).length;

            if (validaFilhos > 0){
                this.$mensagemAviso("Exclusão negada, esse item possui filhos.")
            }
            else{

                axios.get(`/Process/ProProdutosSegmentos/GetAll`)
                    .then(response => {
                        this.ValidaSeg = response.data.filter(x=> x.insSegmentos!= null && x.insSegmentos.psgId == item.psgId)

                        if (this.ValidaSeg.length > 0){
                            this.$mensagemAviso("Exclusão negada, registro em uso em outras telas.")
                        }
                        else{
                            this.$mensagemConfirma('Tem certeza que deseja excluir?')
                            .then((result) => {
                            if (result.isConfirmed) {
                                // Lógica para excluir o item
                                const param1 = item.psgId;
                                axios.delete(`/Process/ProSegmentos/Delete/${param1}`)
                                .then(response => {
                                    if (response.status == 200) {
                                        this.$mensagemAvisoSucesso("Registro Excluido.")
                                        this.CarregaLista();
                                        this.Limpar(false);
                                    }
                                });
                }
                });
                        }
                    });             
            }
           
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                            .then(response => {
                                this.Idiomas = response.data.filter(x => x.idiAtivo);
                                if (this.Idiomas.length == 1) {
                                    this.SegmentoProd.idiId = this.Idiomas[0].idiId;
                                    this.vshowidioma = true;
                                }
                                else{
                                    this.vshowidioma = true;
                                }
                            });
                            this.SegmentoProd.cdtId = 14;
                            this.SegmentoProd.grtId = 3;   
                            this.CarregaLista();
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
