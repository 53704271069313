<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} - </h4><span>{{ InfoTela }}</span>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <div v-if="CamposTela.sgiSegmento && MostraSegmento">
                        <v-tab value="segmentos" class="tabinfo">Segmentos</v-tab>
                         </div>
                        <div v-if="CamposTela.insImagens">
                            <v-tab v-for="tabmenu in ListaInstImgs" :key="tabmenu.lgiId" :value="tabmenu.lgiNome" class="tabinfo">{{tabmenu.lgiNome}}</v-tab>
                        </div>
                        <v-tab v-for="tabmenu in ListaInstArqs" :key="tabmenu.laiId" :value="tabmenu.laiNome" class="tabinfo">{{tabmenu.laiNome}}</v-tab>
                        <v-tab v-for="tabmenu in ListaInstGrids" :key="tabmenu.lgtId" :value="tabmenu.lgtNome" class="tabinfo">{{tabmenu.lgtNome}}</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Institucional.itiId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Institucional.gtiId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Institucional.ctiId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="Institucional.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista()"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col v-if="CamposTela.itiVisivel" cols="12" :md="CamposTela.itiVisivelCol">
                                            <v-select v-model="Institucional.itiVisivel" :label="CamposTela.itiVisivelLabel" :items="StatusVisivel"
                                                item-title="nome" item-value="itiVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiOrdem" cols="12" :md="CamposTela.itiOrdemCol">
                                            <v-text-field v-model="Institucional.itiOrdem" :label="CamposTela.itiOrdemLabel" type="number"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiDestaque" cols="12" :md="CamposTela.itiDestaqueCol">
                                            <v-select v-model="Institucional.itiDestaque" :label="CamposTela.itiDestaqueLabel" :items="StatusDestaque"
                                                item-title="nome" item-value="itiDestaque" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col v-if="CamposTela.ItiDestaqueExtra" cols="12" :md="CamposTela.ItiDestaqueExtraCol">
                                            <v-select v-model="Institucional.itiDestaqueExtra" :label="CamposTela.ItiDestaqueExtraLabel" :items="StatusDestaqueExtra"
                                                item-title="nome" item-value="itiDestaqueExtra" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiNome" cols="12" :md="CamposTela.itiNomeCol">
                                            <v-text-field v-model="Institucional.itiNome"
                                                :label="CamposTela.itiNomeLabel" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiReferencia" cols="12" :md="CamposTela.itiReferenciaCol">
                                            <v-text-field v-model="Institucional.itiReferencia"
                                                :label="CamposTela.itiReferenciaLabel" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra1" cols="12" :md="CamposTela.itiCampoExtra1Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra1"
                                                :label="CamposTela.itiCampoExtra1Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra2" cols="12" :md="CamposTela.itiCampoExtra2Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra2"
                                                :label="CamposTela.itiCampoExtra2Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra3" cols="12" :md="CamposTela.itiCampoExtra3Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra3"
                                                :label="CamposTela.itiCampoExtra3Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra4" cols="12" :md="CamposTela.itiCampoExtra4Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra4"
                                                :label="CamposTela.itiCampoExtra4Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra5" cols="12" :md="CamposTela.itiCampoExtra5Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra5"
                                                :label="CamposTela.itiCampoExtra5Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra6" cols="12" :md="CamposTela.itiCampoExtra6Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra6"
                                                :label="CamposTela.itiCampoExtra6Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra7" cols="12" :md="CamposTela.itiCampoExtra7Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra7"
                                                :label="CamposTela.itiCampoExtra7Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra8" cols="12" :md="CamposTela.itiCampoExtra8Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra8"
                                                :label="CamposTela.itiCampoExtra8Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra9" cols="12" :md="CamposTela.itiCampoExtra9Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra9"
                                                :label="CamposTela.itiCampoExtra9Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCampoExtra10" cols="12" :md="CamposTela.itiCampoExtra10Col">
                                            <v-text-field v-model="Institucional.itiCampoExtra10"
                                                :label="CamposTela.itiCampoExtra10Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiValor1" cols="12" :md="CamposTela.itiValor1Col">
                                            <v-text-field v-model="Institucional.itiValor1" type="number"
                                            @change="Institucional.itiValor1 != null || Institucional.itiValor1 != '' ? (Institucional.itiValor1 = parseFloat(Institucional.itiValor1,10)) : (Institucional.itiValor1 = 0.0)"
                                                :label="CamposTela.itiValor1Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiValor2" cols="12" :md="CamposTela.itiValor2Col">
                                            <v-text-field v-model="Institucional.itiValor2" type="number"
                                            @change="Institucional.itiValor2 != null || Institucional.itiValor2 != '' ? (Institucional.itiValor2 = parseFloat(Institucional.itiValor2,10)) : (Institucional.itiValor2 = 0.0)"
                                                :label="CamposTela.itiValor2Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiValor3" cols="12" :md="CamposTela.itiValor3Col">
                                            <v-text-field v-model="Institucional.itiValor3" type="number"
                                            @change="Institucional.itiValor3 != null || Institucional.itiValor3 != '' ? (Institucional.itiValor3 = parseFloat(Institucional.itiValor3,10)) : (Institucional.itiValor3= 0.0)"
                                                :label="CamposTela.itiValor3Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiValor4" cols="12" :md="CamposTela.itiValor4Col">
                                            <v-text-field v-model="Institucional.itiValor4" type="number"
                                            @change="Institucional.itiValor4 != null || Institucional.itiValor4 != '' ? (Institucional.itiValor4 = parseFloat(Institucional.itiValor4,10)) : (Institucional.itiValor4 = 0.0)"
                                                :label="CamposTela.itiValor4Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiData1" cols="12" :md="CamposTela.itiData1Col">
                                            <v-text-field v-model="Institucional.itiData1" type="date"
                                                :label="CamposTela.itiData1Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiData2" cols="12" :md="CamposTela.itiData2Col">
                                            <v-text-field v-model="Institucional.itiData2" type="date"
                                                :label="CamposTela.itiData2Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiData3" cols="12" :md="CamposTela.itiData3Col">
                                            <v-text-field v-model="Institucional.itiData3" type="date"
                                                :label="CamposTela.itiData3Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiData4" cols="12" :md="CamposTela.itiData4Col">
                                            <v-text-field v-model="Institucional.itiData4" type="date"
                                                :label="CamposTela.itiData4Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiResumo1" cols="12" :md="CamposTela.itiResumo1Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiResumo1Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiResumo1" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiResumo2" cols="12" :md="CamposTela.itiResumo2Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiResumo2Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiResumo2" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiDescricao1" cols="12" :md="CamposTela.itiDescricao1Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiDescricao1Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiDescricao1" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiDescricao2" cols="12" :md="CamposTela.itiDescricao2Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiDescricao2Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiDescricao2" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiDescricao3" cols="12" :md="CamposTela.itiDescricao3Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiDescricao3Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiDescricao3" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiDescricao4" cols="12" :md="CamposTela.itiDescricao4Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiDescricao4Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiDescricao4" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiDescricao5" cols="12" :md="CamposTela.itiDescricao5Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiDescricao5Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiDescricao5" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiDescricao6" cols="12" :md="CamposTela.itiDescricao6Col">
                                            <small style="padding-bottom:5px;"><strong>{{ CamposTela.itiDescricao6Label }}</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Institucional.itiDescricao6" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiTextoBtn1" cols="12" :md="CamposTela.itiTextoBtn1Col">
                                            <v-text-field v-model="Institucional.itiTextoBtn1"
                                                :label="CamposTela.itiTextoBtn1Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiLinkBtn1" cols="12" :md="CamposTela.itiLinkBtn1Col">
                                            <v-text-field v-model="Institucional.itiLinkBtn1"
                                                :label="CamposTela.itiLinkBtn1Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiTextoBtn2" cols="12" :md="CamposTela.itiTextoBtn2Col">
                                            <v-text-field v-model="Institucional.itiTextoBtn2"
                                                :label="CamposTela.itiTextoBtn2Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiLinkBtn2" cols="12" :md="CamposTela.itiLinkBtn2Col">
                                            <v-text-field v-model="Institucional.itiLinkBtn2"
                                                :label="CamposTela.itiLinkBtn2Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiTextoBtn3" cols="12" :md="CamposTela.itiTextoBtn3Col">
                                            <v-text-field v-model="Institucional.itiTextoBtn3"
                                                :label="CamposTela.itiTextoBtn3Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiLinkBtn3" cols="12" :md="CamposTela.itiLinkBtn3Col">
                                            <v-text-field v-model="Institucional.itiLinkBtn3"
                                                :label="CamposTela.itiLinkBtn3Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiTextoBtn4" cols="12" :md="CamposTela.itiTextoBtn4Col">
                                            <v-text-field v-model="Institucional.itiTextoBtn4"
                                                :label="CamposTela.itiTextoBtn4Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiLinkBtn4" cols="12" :md="CamposTela.itiLinkBtn4Col">
                                            <v-text-field v-model="Institucional.itiLinkBtn4"
                                                :label="CamposTela.itiLinkBtn4Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCor1" cols="12" :md="CamposTela.itiCor1Col">
                                            <v-text-field v-model="Institucional.itiCor1" type="color"
                                                :label="CamposTela.itiCor1Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCor2" cols="12" :md="CamposTela.itiCor2Col">
                                            <v-text-field v-model="Institucional.itiCor2" type="color"
                                                :label="CamposTela.itiCor2Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col  v-if="CamposTela.itiCor3" cols="12" :md="CamposTela.itiCor3Col">
                                            <v-text-field v-model="Institucional.itiCor3" type="color"
                                                :label="CamposTela.itiCor3Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col v-if="CamposTela.itiCor4" cols="12" :md="CamposTela.itiCor4Col">
                                            <v-text-field v-model="Institucional.itiCor4" type="color"
                                                :label="CamposTela.itiCor4Label" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <!--Segmentos-->
                        <v-window-item value="segmentos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Seleção de Segmentos</div>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="10">
                                            <v-select v-model="InstitucionalSegs.sgiId" label="Segmentos" :items="ListaHierarquiaSeg"
                                                item-title="sgiNomeSegmento" item-value="sgiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                        <button type="button" class="btn btn-success" @click="AddSegmentos(Institucional.itiId)">
                                            Incluir
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-table>
                                            <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Hierarquia de Segmentos
                                                </th>
                                                <th class="text-center">
                                                    Actions
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in ListaSegmentos" :key="item.issId">
                                                <td>{{ item.Hierarquia }}</td>
                                                <td style="text-align:center;">  
                                                    <v-btn icon @click.prevent="DeleteSegmentos(item)" variant="text">
                                                        <v-icon>mdi-delete-forever-outline</v-icon>
                                                        <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </v-table>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <!--Imagens-->
                        <v-window-item v-for="tabmenu in ListaInstImgs" :key="tabmenu.lgiId" :value="tabmenu.lgiNome" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload de até {{tabmenu.lgiQtd}} {{tabmenu.lgiQtd > 1 ? 'imagens com dimensões' : 'imagem com dimensão'}} : Largura: {{tabmenu.lgiLargura}}px - Altura: {{tabmenu.lgiAltura}}px</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="3">
                                         <v-file-input v-model="selectedFile" multiple label="Imagens" variant="underlined"
                                            prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, image/gif, image/svg"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddImagens(tabmenu.lgiId)">
                                            Carregar Imagens
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row v-for="imgs in InstitucionalImgs.filter(x=> x.insListaGaleriaInstImagens.lgiId == tabmenu.lgiId)" :key="imgs.ItgId">
                                            <v-col cols="12" md="4" v-show="false">
                                                <v-text-field v-model="imgs.itgId" label="Id" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-col cols="12" v-if="CamposTela.itgUrlImagem" md="12">
                                                    <v-img :src="$imgURL + imgs.itgUrlImagem" variant="underlined"></v-img>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" md="8" v-if="CamposTela.itgNome || CamposTela.itgOrdem || CamposTela.itgVisivel || CamposTela.itgAlt || CamposTela.itgResumo">
                                                <v-row>
                                                <v-col cols="12"  v-if="CamposTela.itgNome" :md="CamposTela.itgNomeCol">
                                                    <v-text-field v-model="imgs.itgNome" :label="CamposTela.itgNomeLabel" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="CamposTela.itgOrdem" :md="CamposTela.itgOrdemCol">
                                                    <v-text-field v-model="imgs.itgOrdem" :label="CamposTela.itgOrdemLabel" type="number" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="CamposTela.itgVisivel" :md="CamposTela.itgVisivelCol">
                                                    <v-select v-model="imgs.itgVisivel" :label="CamposTela.itgVisivelLabel" :items="StatusImgVisivel"
                                                        item-title="nome" item-value="itgVisivel" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" v-if="CamposTela.itgAlt" :md="CamposTela.itgAltCol">
                                                    <v-text-field v-model="imgs.itgAlt" :label="CamposTela.itgAltLabel" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="CamposTela.itgResumo" :md="CamposTela.itgResumoCol">
                                                    <small style="padding-bottom:5px;"><strong>{{ CamposTela.itgResumoLabel }}</strong></small>
                                                    <ckeditor :editor="editor" @ready="onReady" v-model="imgs.itgResumo" :config="$ckconfig"></ckeditor>
                                                </v-col>
                                            </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn v-if="CamposTela.itgNome || CamposTela.itgOrdem || CamposTela.itgVisivel || CamposTela.itgAlt || CamposTela.itgResumo" icon @click="SaveImages(imgs)" style="margin-right: 10px;" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>

                                                <v-btn icon @click.prevent="DeleteImages(imgs)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                         <!--Arquivos-->
                        <v-window-item v-for="tabmenu in ListaInstArqs" :key="tabmenu.laiId" :value="tabmenu.laiNome" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload de até {{tabmenu.laiQtd}} {{tabmenu.laiQtd > 1 ? 'arquivos com até' : 'arquivo com até'}} : Peso: {{tabmenu.laiPesoMb}}mb</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="3">
                                         <v-file-input v-model="selectedFileArq" multiple label="Arquivos" variant="underlined"
                                            prepend-icon="mdi-file-upload-outline" accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddArquivos(tabmenu.laiId)">
                                            Carregar Arquivos
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row v-for="arqs in InstitucionalArqs.filter(x=> x.insListaGaleriaInstArquivos.laiId == tabmenu.laiId)" :key="arqs.IagId">
                                            <v-col cols="12" md="4" v-show="false">
                                                <v-text-field v-model="arqs.iagId" label="Id" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-col cols="12" v-if="CamposTela.iagUrlArquivo" md="12" style="text-align:center;">
                                                    <a :href="$imgURL + arqs.iagUrlArquivo" target="_blank">
                                                        <v-icon style="font-size:60px;">mdi-file-eye-outline</v-icon><br>
                                                        <span style="font-size:8px;width: 100%;">{{ arqs.iagUrlArquivo }}</span>
                                                    </a>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" md="8" v-if="CamposTela.iagNome || CamposTela.iagOrdem || CamposTela.iagVisivel || CamposTela.iagResumo">
                                                <v-row>
                                                <v-col cols="12"  v-if="CamposTela.iagNome" :md="CamposTela.iagNomeCol">
                                                    <v-text-field v-model="arqs.iagNome" :label="CamposTela.iagNomeLabel" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="CamposTela.iagOrdem" :md="CamposTela.iagOrdemCol">
                                                    <v-text-field v-model="arqs.iagOrdem" :label="CamposTela.iagOrdemLabel" type="number" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="CamposTela.iagVisivel" :md="CamposTela.iagVisivelCol">
                                                    <v-select v-model="arqs.iagVisivel" :label="CamposTela.iagVisivelLabel" :items="StatusArqVisivel"
                                                        item-title="nome" item-value="iagVisivel" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" v-if="CamposTela.iagResumo" :md="CamposTela.iagResumoCol">
                                                    <small style="padding-bottom:5px;"><strong>{{ CamposTela.iagResumoLabel }}</strong></small>
                                                    <ckeditor :editor="editor" @ready="onReady" v-model="arqs.iagResumo" :config="$ckconfig"></ckeditor>
                                                </v-col>
                                            </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn v-if="CamposTela.iagNome || CamposTela.iagOrdem || CamposTela.iagVisivel || CamposTela.iagResumo" icon @click="SaveArquivos(arqs)" style="margin-right: 10px;" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>

                                                <v-btn icon @click.prevent="DeleteArquivos(arqs)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                         <!--Grids-->
                        <v-window-item v-for="tabmenu in ListaInstGrids" :key="tabmenu.lgtId" :value="tabmenu.lgtNome" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Lista Itens Grid</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="4" v-show="false">
                                        <v-text-field v-model="InstitucionalGrids.igsId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsNome" :md="CamposTela.igsNomeCol">
                                        <v-text-field v-model="InstitucionalGrids.igsNome" :label="CamposTela.igsNomeLabel" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsCampoExtra1" :md="CamposTela.igsCampoExtra1Col">
                                        <v-text-field v-model="InstitucionalGrids.igsCampoExtra1" :label="CamposTela.igsCampoExtra1Label" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsCampoExtra2" :md="CamposTela.igsCampoExtra2Col">
                                        <v-text-field v-model="InstitucionalGrids.igsCampoExtra2" :label="CamposTela.igsCampoExtra2Label" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsCampoExtra3" :md="CamposTela.igsCampoExtra3Col">
                                        <v-text-field v-model="InstitucionalGrids.igsCampoExtra3" :label="CamposTela.igsCampoExtra3Label" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsCampoExtra4" :md="CamposTela.igsCampoExtra4Col">
                                        <v-text-field v-model="InstitucionalGrids.igsCampoExtra4" :label="CamposTela.igsCampoExtra4Label" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsOrdem" :md="CamposTela.igsOrdemCol">
                                        <v-text-field v-model="InstitucionalGrids.igsOrdem" :label="CamposTela.igsOrdemLabel" type="number" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsVisivel" :md="CamposTela.igsVisivelCol">
                                        <v-select v-model="InstitucionalGrids.igsVisivel" :label="CamposTela.igsVisivelLabel" :items="StatusGridVisivel"
                                            item-title="nome" item-value="igsVisivel" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" v-if="CamposTela.igsUrlImagem" :md="CamposTela.igsUrlImagemCol">
                                         <v-file-input v-model="selectedFileGrid" :label="CamposTela.igsUrlImagemLabel" variant="underlined"
                                            prepend-icon="mdi-file-upload-outline" accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddGrids(tabmenu.lgtId)">
                                            Adicionar
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th v-if="CamposTela.igsNome" class="text-left">
                                                       {{ CamposTela.igsNomeLabel }}
                                                    </th>
                                                    <th v-if="CamposTela.igsCampoExtra1" class="text-left">
                                                       {{ CamposTela.igsCampoExtra1Label }}
                                                    </th>
                                                    <th v-if="CamposTela.igsCampoExtra2" class="text-left">
                                                       {{ CamposTela.igsCampoExtra2Label }}
                                                    </th>
                                                    <th v-if="CamposTela.igsCampoExtra3" class="text-left">
                                                       {{ CamposTela.igsCampoExtra3Label }}
                                                    </th>
                                                    <th v-if="CamposTela.igsCampoExtra4" class="text-left">
                                                       {{ CamposTela.igsCampoExtra4Label }}
                                                    </th>
                                                    <th v-if="CamposTela.igsOrdem" class="text-left">
                                                       {{ CamposTela.igsOrdemLabel }}
                                                    </th>
                                                    <th v-if="CamposTela.igsVisivel" class="text-left">
                                                       {{ CamposTela.igsVisivelLabel }}
                                                    </th>
                                                    <th v-if="CamposTela.igsUrlImagem" class="text-center">
                                                       {{ CamposTela.igsUrlImagemLabel }}
                                                    </th>
                                                    <th class="text-center">
                                                       Actions
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in GridsItens.filter(x=> x.insListaGaleriaInstGrids.lgtId == tabmenu.lgtId)" :key="item.igsId">
                                                    <td v-if="CamposTela.igsNome">{{ item.igsNome }}</td>
                                                    <td v-if="CamposTela.igsCampoExtra1">{{ item.igsCampoExtra1 }}</td>
                                                    <td v-if="CamposTela.igsCampoExtra2">{{ item.igsCampoExtra2 }}</td>
                                                    <td v-if="CamposTela.igsCampoExtra3">{{ item.igsCampoExtra3 }}</td>
                                                    <td v-if="CamposTela.igsCampoExtra4">{{ item.igsCampoExtra4 }}</td>
                                                    <td v-if="CamposTela.igsOrdem">{{ item.igsOrdem }}</td>
                                                    <td v-if="CamposTela.igsVisivel">{{ item.igsVisivel?'Sim':'Não' }}</td>
                                                    <td v-if="CamposTela.igsUrlImagem" style="text-align:center;">
                                                        <a v-if="item.igsUrlImagem!=''" :href="$imgURL + item.igsUrlImagem" target="_blank">
                                                            <v-icon>mdi-file-eye-outline</v-icon>
                                                       </a>
                                                   </td>
                                                    <td style="text-align:center;">  
                                                        <v-btn icon @click.prevent="DeleteGrids(item)" variant="text">
                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Institucional.itiId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ListInstitucional)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-itiVisivel="item">
                            <span>{{ item.itiVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-itiData1="item">
                            <span>{{ item.itiData1!=null? formattedDate(item.itiData1) : null }}</span>
                        </template>
                        <template #item-itiData2="item">
                            <span>{{ item.itiData2!=null? formattedDate(item.itiData2) : '' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'
import { useRoute } from 'vue-router';

export default {
    name: 'InstitucionalView',
    components: {
        EasyDataTable,
        moment
    },
    setup() {
        const route = useRoute();
        const id = route.params.id;
        const nome = route.params.nome;
        return {
        id
        };
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'',
        InfoTela:'',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[],
        CamposTela:[],
        //dados
        Institucional: {
            itiId: 0,
            gtiId: 0,
            ctiId: 0,
            idiId: '',
            itiVisivel: '',
            itiOrdem: null,
            itiDestaque: '',
            itiDestaqueExtra: '',
            itiNome: null,
            itiReferencia: null,
            itiCampoExtra1: null,
            itiCampoExtra2: null,
            itiCampoExtra3: null,
            itiCampoExtra4: null,
            itiCampoExtra5: null,
            itiCampoExtra6: null,
            itiCampoExtra7: null,
            itiCampoExtra8: null,
            itiCampoExtra9: null,
            itiCampoExtra10: null,
            itiResumo1: '',
            itiResumo2: '',
            itiDescricao1: '',
            itiDescricao2: '',
            itiDescricao3: '',
            itiDescricao4: '',
            itiDescricao5: '',
            itiDescricao6: '',
            itiTextoBtn1: null,
            itiTextoBtn2: null,
            itiTextoBtn3: null,
            itiTextoBtn4: null,
            itiLinkBtn1: null,
            itiLinkBtn2: null,
            itiLinkBtn3: null,
            itiLinkBtn4: null,
            itiCor1: null,
            itiCor2: null,
            itiCor3: null,
            itiCor4: null,
            itiValor1: 0,
            itiValor2: 0,
            itiValor3: 0,
            itiValor4: 0,
            itiData1: '',
            itiData2: '',
            itiData3: '',
            itiData4: '',
        },
        ListInstitucional: [],
        StatusVisivel: [{ nome: "Sim", itiVisivel: true }, { nome: "Não", itiVisivel: false }],
        StatusDestaque: [{ nome: "Sim", itiDestaque: true }, { nome: "Não", itiDestaque: false }],
        StatusDestaqueExtra: [{ nome: "Sim", itiDestaqueExtra: true }, { nome: "Não", itiDestaqueExtra: false }],
        //dados
        //imagens
        InstitucionalImgs:[],
        ListaInstImgs:[],
        ImagemInst:{},
        selectedFile: null,
        StatusImgVisivel: [{ nome: "Sim", itgVisivel: true }, { nome: "Não", itgVisivel: false }],
        //imagens
        //arquivos
        InstitucionalArqs:[],
        ListaInstArqs:[],
        ArquivoInst:{},
        selectedFileArq: null,
        StatusArqVisivel: [{ nome: "Sim", iagVisivel: true }, { nome: "Não", iagVisivel: false }],
        //arquivos
        //Grids
        InstitucionalGrids:{
            igsId:0,
            igsNome:'',
            igsCampoExtra1:'',
            igsCampoExtra2:'',
            igsCampoExtra3:'',
            igsCampoExtra4:'',
            igsOrdem:'',
            igsVisivel:'',
            igsUrlImagem:''
        },
        GridsItens:[],
        ListaInstGrids:[],
        ArquivoInst:{},
        selectedFileGrid: null,
        StatusGridVisivel: [{ nome: "Sim", igsVisivel: true }, { nome: "Não", igsVisivel: false }],
        GridsImgs:[],
        //Grids
        //Segmentos
        InstitucionalSegs:{
            issId:0,
            itiId:0,
            sgiId:'',
            issOrdem:0,
            Hierarquia:'',
        },
        ListaHierarquiaSeg:[],
        ListaSegmentos:[],
        ListSegmentosInstGeral:[],
        MostraSegmento:false,
        //Segmentos
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                itiOrdem: parseInt(item.itiOrdem),
            };
            });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListInstitucional = this.ListInstitucionalGeral.filter(x=> x.idiId == this.Institucional.idiId);
        },
        Limpar() {
            this.Institucional.itiId=0;
            this.Institucional.gtiId=0;
            this.Institucional.ctiId=0;
            this.Institucional.idiId='';
            this.Institucional.itiVisivel='';
            this.Institucional.itiOrdem=null;
            this.Institucional.itiDestaque='';
            this.Institucional.itiDestaqueExtra='';
            this.Institucional.itiNome=null;
            this.Institucional.itiReferencia=null;
            this.Institucional.itiCampoExtra1=null;
            this.Institucional.itiCampoExtra2=null;
            this.Institucional.itiCampoExtra3=null;
            this.Institucional.itiCampoExtra4=null;
            this.Institucional.itiCampoExtra5=null;
            this.Institucional.itiCampoExtra6=null;
            this.Institucional.itiCampoExtra7=null;
            this.Institucional.itiCampoExtra8=null;
            this.Institucional.itiCampoExtra9=null;
            this.Institucional.itiCampoExtra10=null;
            this.Institucional.itiResumo1='';
            this.Institucional.itiResumo2='';
            this.Institucional.itiDescricao1='';
            this.Institucional.itiDescricao2='';
            this.Institucional.itiDescricao3='';
            this.Institucional.itiDescricao4='';
            this.Institucional.itiDescricao5='';
            this.Institucional.itiDescricao6='';
            this.Institucional.itiTextoBtn1=null;
            this.Institucional.itiTextoBtn2=null;
            this.Institucional.itiTextoBtn3=null;
            this.Institucional.itiTextoBtn4=null;
            this.Institucional.itiLinkBtn1=null;
            this.Institucional.itiLinkBtn2=null;
            this.Institucional.itiLinkBtn3=null;
            this.Institucional.itiLinkBtn4=null;
            this.Institucional.itiCor1=null;
            this.Institucional.itiCor2=null;
            this.Institucional.itiCor3=null;
            this.Institucional.itiCor4=null;
            this.Institucional.itiValor1=0;
            this.Institucional.itiValor2=0;
            this.Institucional.itiValor3=0;
            this.Institucional.itiValor4=0;
            this.Institucional.itiData1='';
            this.Institucional.itiData2='';
            this.Institucional.itiData3='';
            this.Institucional.itiData4='';
            this.ListaInstImgs = [];
            this.ListaInstArqs = [];
            this.ListaInstGrids = [];
            this.MostraSegmento = false;
            this.tab = 'dados';
            this.Get();
        },
        LimpaGridInput(){
            this.InstitucionalGrids.igsId=0;
            this.InstitucionalGrids.igsNome= '';
            this.InstitucionalGrids.igsCampoExtra1= '';
            this.InstitucionalGrids.igsCampoExtra2= '';
            this.InstitucionalGrids.igsCampoExtra3= '';
            this.InstitucionalGrids.igsCampoExtra4= '';
            this.InstitucionalGrids.igsOrdem= '';
            this.InstitucionalGrids.igsVisivel= '';
            this.InstitucionalGrids.igsUrlImagem= '';
            this.selectedFileGrid = null;
        },
        async AddImagens(value){           
            if (this.selectedFile != '' && this.selectedFile != null) {
                var qtdebase = this.InstitucionalImgs.filter(x=> x.insListaGaleriaInstImagens.lgiId == value).length;
                if ((this.selectedFile.length + qtdebase) <= this.ListaInstImgs.filter(w=> w.lgiId == value)[0].lgiQtd){
                    let selected2Promises = this.selectedFile.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ImagemInst.itgUrlImagem = uploadSuccess.data.nameFile;
                                this.ImagemInst.itgVisivel = true;
                                this.ImagemInst.InsListaGaleriaInstImagens = { LgiId: value };
                                this.ImagemInst.itiId = this.Institucional.itiId
                                this.ImagemInst.itgResumo = "";
                                return axios.post(`/Process/InsInstitucionalImagens/Add`, this.ImagemInst);
                            } else {
                                this.$mensagemErro("Não foi possível carregar a imagem.");
                                return null;
                            }
                    });

                    Promise.all(selected2Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaImagens();
                            this.selectedFile = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Alguma imagem pode ter dado erro na carga." + error);
                        });
                    }
                    else{
                        this.$mensagemAviso("Quantidade máxima de imagens excedida.")
                    }
                }
                else{
                    this.$mensagemAviso("Selecione uma imagem.")
                }
        },
        SaveImages(value){
            var _imginfo = value;
            if (_imginfo.itgOrdem === "" || _imginfo.itgOrdem === null) {
                _imginfo.itgOrdem = 0;
            }
            else {
                _imginfo.itgOrdem = parseInt(_imginfo.itgOrdem);
            }

            if (_imginfo.itgResumo === "" || _imginfo.itgResumo === null){
                _imginfo.itgResumo = "";
            }
            
            axios.put(`/Process/InsInstitucionalImagens/Update`, _imginfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaImagens();
                        }
                    });
        },
        DeleteImages(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.itgId;
                axios.delete(`/Process/InsInstitucionalImagens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaImagens();
                    }
                });
            }
            });
        },
        async AddArquivos(value){           
            if (this.selectedFileArq != '' && this.selectedFileArq != null) {
                var qtdebase = this.InstitucionalArqs.filter(x=> x.insListaGaleriaInstArquivos.laiId == value).length;
                if ((this.selectedFileArq.length + qtdebase) <= this.ListaInstArqs.filter(w=> w.laiId == value)[0].laiQtd){
                    let selected3Promises = this.selectedFileArq.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ArquivoInst.iagUrlArquivo = uploadSuccess.data.nameFile;
                                this.ArquivoInst.iagVisivel = true;
                                this.ArquivoInst.insListaGaleriaInstArquivos = { LaiId: value };
                                this.ArquivoInst.itiId = this.Institucional.itiId
                                return axios.post(`/Process/InsInstitucionalArquivos/Add`, this.ArquivoInst);
                            } else {
                                this.$mensagemErro("Não foi possível carregar arquivo.");
                                return null;
                            }
                    });

                    Promise.all(selected3Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaArquivos();
                            this.selectedFileArq = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Algum arquivo pode ter dado erro na carga." + error);
                        });
                    }
                    else{
                        this.$mensagemAviso("Quantidade máxima de arquivos excedida.")
                    }
                }
                else{
                    this.$mensagemAviso("Selecione um arquivo.")
                }
        },
        SaveArquivos(value){
            var _arqinfo = value;
            if (_arqinfo.iagOrdem === "" || _arqinfo.iagOrdem === null) {
                _arqinfo.iagOrdem = 0;
            }
            else {
                _arqinfo.iagOrdem = parseInt(_arqinfo.iagOrdem);
            }
            
            axios.put(`/Process/InsInstitucionalArquivos/Update`, _arqinfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaArquivos();
                        }
                    });
        },
        DeleteArquivos(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.iagId;
                axios.delete(`/Process/InsInstitucionalArquivos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaArquivos();
                    }
                });
            }
            });
        },
        async AddGrids(value){
            if (this.selectedFileGrid != '' && this.selectedFileGrid != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFileGrid[0]);
                    if (uploadSuccess.status == 200) {
                            this.InstitucionalGrids.igsUrlImagem = uploadSuccess.data.nameFile;
                            this.InstitucionalGrids.insListaGaleriaInstGrids = { LgtId: value };
                            this.InstitucionalGrids.itiId = this.Institucional.itiId
                            if (this.InstitucionalGrids.igsOrdem === "" || this.InstitucionalGrids.igsOrdem === null) {
                                this.InstitucionalGrids.igsOrdem = 0;
                            }
                            else {
                                this.InstitucionalGrids.igsOrdem = parseInt(this.InstitucionalGrids.igsOrdem);
                            }
                            if (this.InstitucionalGrids.igsVisivel === "" || this.InstitucionalGrids.igsVisivel === null) {
                                this.InstitucionalGrids.igsVisivel = true;
                            }
                        axios.post(`/Process/InsInstitucionalGrids/Add`, this.InstitucionalGrids)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaGrids();
                                this.LimpaGridInput();
                            }
                        });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.InstitucionalGrids.igsUrlImagem = "";
                    this.InstitucionalGrids.insListaGaleriaInstGrids = { LgtId: value };
                    this.InstitucionalGrids.itiId = this.Institucional.itiId
                    if (this.InstitucionalGrids.igsOrdem === "" || this.InstitucionalGrids.igsOrdem === null) {
                        this.InstitucionalGrids.igsOrdem = 0;
                    }
                    else {
                        this.InstitucionalGrids.igsOrdem = parseInt(this.InstitucionalGrids.igsOrdem);
                    }
                    if (this.InstitucionalGrids.igsVisivel === "" || this.InstitucionalGrids.igsVisivel === null) {
                        this.InstitucionalGrids.igsVisivel = true;
                    }
                    axios.post(`/Process/InsInstitucionalGrids/Add`, this.InstitucionalGrids)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaGrids();
                                this.LimpaGridInput();
                            }
                        });
                }
        },
        DeleteGrids(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.igsId;
                axios.delete(`/Process/InsInstitucionalGrids/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaGrids();
                    }
                });
            }
            });
        },
        Add() {
            if (!this.CamposTela.multi && this.ListInstitucional != '') {
                this.$mensagemAviso("Somente uma informação é possível para essa tela.")
            }
            else {
                if (this.Institucional.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Institucional.itiNome == '' && this.CamposTela.itiNome) {
                    this.$mensagemAviso("Campo " + this.CamposTela.itiNomeLabel + " Obrigatório.")
                }
                else if (this.Institucional.itiVisivel === '' && this.CamposTela.itiVisivel) {
                    this.$mensagemAviso("Campo " + this.CamposTela.itiVisivelLabel + " Obrigatório.")
                }
                else if (this.Institucional.itiOrdem === '' && this.CamposTela.itiOrdem) {
                    this.$mensagemAviso("Campo " + this.CamposTela.itiOrdemLabel + " Obrigatório.")
                }
                else if (this.Institucional.itiDestaque === '' && this.CamposTela.itiDestaque) {
                    this.$mensagemAviso("Campo " + this.CamposTela.itiDestaqueLabel + " Obrigatório.")
                }
                else if (this.Institucional.itiDestaqueExtra === '' && this.CamposTela.itiDestaqueExtra) {
                    this.$mensagemAviso("Campo " + this.CamposTela.itiDestaqueExtraLabel + " Obrigatório.")
                }
                else {
                    if (this.Institucional.itiVisivel === "" || this.Institucional.itiVisivel === null) {
                        this.Institucional.itiVisivel = false;
                    }
                    if (this.Institucional.itiDestaque === "" || this.Institucional.itiDestaque === null) {
                        this.Institucional.itiDestaque = false;
                    }
                    if (this.Institucional.itiDestaqueExtra === "" || this.Institucional.itiDestaqueExtra === null) {
                        this.Institucional.itiDestaqueExtra = false;
                    }
                    if (this.Institucional.itiOrdem === "" || this.Institucional.itiOrdem === null) {
                        this.Institucional.itiOrdem = 0;
                    }
                    else {
                        this.Institucional.itiOrdem = parseInt(this.Institucional.itiOrdem);
                    }
                    this.Institucional.admGrupoTelasInst = { GtiId: this.Institucional.gtiId }
                    this.Institucional.admCadastroTelasInst = { CtiId: this.Institucional.ctiId }
                    this.Institucional.cadIdiomas = { IdiId: this.Institucional.idiId }

                    if (this.Institucional.itiData1 === "") {
                        this.Institucional.itiData1 = null;
                    }
                    if (this.Institucional.itiData2 === "") {
                        this.Institucional.itiData2 = null;
                    }
                    if (this.Institucional.itiData3 === "") {
                        this.Institucional.itiData3 = null;
                    }
                    if (this.Institucional.itiData4 === "") {
                        this.Institucional.itiData4 = null;
                    }

                    if (this.Institucional.itiValor1 === "") {
                        this.Institucional.itiValor1 = null;
                    }
                    else {
                        this.Institucional.itiValor1 = parseFloat(this.Institucional.itiValor1)
                    }
                    if (this.Institucional.itiValor2 === "") {
                        this.Institucional.itiValor2 = null;
                    }
                    else {
                        this.Institucional.itiValor2 = parseFloat(this.Institucional.itiValor2)
                    }
                    if (this.Institucional.itiValor3 === "") {
                        this.Institucional.itiValor3 = null;
                    }
                    else {
                        this.Institucional.itiValor3 = parseFloat(this.Institucional.itiValor3)
                    }
                    if (this.Institucional.itiValor4 === "") {
                        this.Institucional.itiValor4 = null;
                    }
                    else {
                        this.Institucional.itiValor4 = parseFloat(this.Institucional.itiValor4)
                    }
                    axios.post(`/Process/InsInstitucional/Add`, this.Institucional)
                        .then(response => {
                            if (response.data > 0) {
                                if (this.CamposTela.insImagens){
                                    this.Institucional.itiId = response.data;
                                    this.Get();
                                    this.EditGrid(this.Institucional);
                                    this.$mensagemAvisoSucesso("Registro Incluído. Abas complementares liberadas.")
                                }
                                else{
                                   this.$mensagemAvisoSucesso("Registro Incluído.")
                                   this.Get();
                                   this.Limpar();
                                   this.tab = 'dados';
                                }                              
                            }
                        });
                }
            }
        },
        Put() {
            if (this.Institucional.idiId == '') {
                this.$mensagemAviso("Campo Idioma Obrigatório.")
            }
            else if (this.Institucional.itiNome == '' && this.CamposTela.itiNome) {
                this.$mensagemAviso("Campo " + this.CamposTela.itiNomeLabel + " Obrigatório.")
            }
            else if (this.Institucional.itiVisivel === '' && this.CamposTela.itiVisivel){
                this.$mensagemAviso("Campo " + this.CamposTela.itiVisivelLabel + " Obrigatório.")
            }
            else if (this.Institucional.itiOrdem === '' && this.CamposTela.itiOrdem){
                this.$mensagemAviso("Campo " + this.CamposTela.itiOrdemLabel + " Obrigatório.")
            }
            else if (this.Institucional.itiDestaque === '' && this.CamposTela.itiDestaque){
                this.$mensagemAviso("Campo " + this.CamposTela.itiDestaqueLabel + " Obrigatório.")
            }
            else if (this.Institucional.itiDestaqueExtra === '' && this.CamposTela.itiDestaqueExtra){
                this.$mensagemAviso("Campo " + this.CamposTela.itiDestaqueExtraLabel + " Obrigatório.")
            }
            else {
                if (this.Institucional.itiVisivel === "" || this.Institucional.itiVisivel === null){
                    this.Institucional.itiVisivel = false;
                }
                if (this.Institucional.itiDestaque === "" || this.Institucional.itiDestaque === null){
                    this.Institucional.itiDestaque = false;
                }
                if (this.Institucional.itiDestaqueExtra === "" || this.Institucional.itiDestaqueExtra === null){
                    this.Institucional.itiDestaqueExtra = false;
                }
                if (this.Institucional.itiOrdem === "" || this.Institucional.itiOrdem === null){
                    this.Institucional.itiOrdem = 0;
                }
                else{
                    this.Institucional.itiOrdem = parseInt(this.Institucional.itiOrdem);
                }
                this.Institucional.admGrupoTelasInst = {GtiId:this.Institucional.gtiId}
                this.Institucional.admCadastroTelasInst = {CtiId:this.Institucional.ctiId}
                this.Institucional.cadIdiomas = {IdiId:this.Institucional.idiId}

                if (this.Institucional.itiData1 === ""){
                    this.Institucional.itiData1 = null;
                }
                if (this.Institucional.itiData2 === ""){
                    this.Institucional.itiData2 = null;
                }
                if (this.Institucional.itiData3 === ""){
                    this.Institucional.itiData3 = null;
                }
                if (this.Institucional.itiData4 === ""){
                    this.Institucional.itiData4 = null;
                }

                if (this.Institucional.itiValor1 === ""){
                    this.Institucional.itiValor1 = null;
                }
                else{
                    this.Institucional.itiValor1 = parseFloat(this.Institucional.itiValor1)
                }
                if (this.Institucional.itiValor2 === ""){
                    this.Institucional.itiValor2 = null;
                }
                else{
                    this.Institucional.itiValor2 = parseFloat(this.Institucional.itiValor2)
                }
                if (this.Institucional.itiValor3 === ""){
                    this.Institucional.itiValor3 = null;
                }
                else{
                    this.Institucional.itiValor3 = parseFloat(this.Institucional.itiValor3)
                }
                if (this.Institucional.itiValor4 === ""){
                    this.Institucional.itiValor4 = null;
                }
                else{
                    this.Institucional.itiValor4 = parseFloat(this.Institucional.itiValor4)
                }
                axios.put(`/Process/InsInstitucional/Update`, this.Institucional)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        CarregaImagens(){
            axios.get(`/Process/InsInstitucionalImagens/GetByCtiId/${this.id}/${this.Institucional.itiId}`)
                    .then(response => {
                        this.InstitucionalImgs = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.itgOrdem) || 0;
                                                                                const valorB = parseInt(b.itgOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });
                    });
        },
        CarregaArquivos(){
            axios.get(`/Process/InsInstitucionalArquivos/GetByCtiId/${this.id}/${this.Institucional.itiId}`)
                    .then(response => {
                        this.InstitucionalArqs = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.iagOrdem) || 0;
                                                                                const valorB = parseInt(b.iagOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });
                    });
        },
        CarregaGrids(){
            axios.get(`/Process/InsInstitucionalGrids/GetByCtiId/${this.id}/${this.Institucional.itiId}`)
                    .then(response => {
                        this.GridsItens = response.data.sort((a, b) => {
                                                                        const valorA = parseInt(a.igsOrdem) || 0;
                                                                        const valorB = parseInt(b.igsOrdem) || 0;
                                                                        return valorA - valorB;
                                                                    });
                    });
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.itiId;
                axios.delete(`/Process/InsInstitucional/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item          
            if (item.itiData1 != "" && item.itiData1 != null) {
                item.itiData1 = this.$moment(item.itiData1).format('YYYY-MM-DD')
            }
            else{
                item.itiData1 = '';
            }
            if (item.itiData2 != "" && item.itiData2 != null) {
                item.itiData2 = this.$moment(item.itiData2).format('YYYY-MM-DD')
            }
            else{
                item.itiData2 = '';
            }
            if (item.itiData3 != "" && item.itiData3 != null) {
                item.itiData3 = this.$moment(item.itiData3).format('YYYY-MM-DD')
            }
            else{
                item.itiData3 = '';
            }
            if (item.itiData4 != "" && item.itiData4 != null) {
                item.itiData4 = this.$moment(item.itiData4).format('YYYY-MM-DD')
            }
            else{
                item.itiData4 = '';
            }
            this.Institucional = item;

            //carrega lista de imagens
            axios.get(`/Process/InsListaGaleriaInstImagens/GetByCtiId/${this.id}`)
                    .then(response => {
                        this.ListaInstImgs = response.data.filter(x => x.lgiVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.lgiOrdem) || 0;
                                                                                          const valorB = parseInt(b.lgiOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                    
                        if (this.ListaInstImgs!=''){
                           this.CarregaImagens();
                        }
                    });

              //carrega lista de Arquivos
              axios.get(`/Process/InsListaGaleriaInstArquivos/GetByCtiId/${this.id}`)
                    .then(response => {
                        this.ListaInstArqs = response.data.filter(x => x.laiVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.laiOrdem) || 0;
                                                                                          const valorB = parseInt(b.laiOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                        if (this.ListaInstArqs!=''){
                           this.CarregaArquivos();
                        }
                    });
            
            //carrega lista de Grids
            axios.get(`/Process/InsListaGaleriaInstGrids/GetByCtiId/${this.id}`)
                    .then(response => {
                        this.ListaInstGrids = response.data.filter(x => x.lgtVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.lgtOrdem) || 0;
                                                                                          const valorB = parseInt(b.lgtOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                        if (this.ListaInstGrids!=''){
                           this.CarregaGrids();
                        }
                    });

                    this.CarregaListaSegmentos();
                    this.MostraSegmento = true;

            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "itiId", sortable: true, });
            if (this.CamposTela.itiNome) {
                this.headers.push({ text: this.CamposTela.itiNomeLabel, value: "itiNome", sortable: true, });
            }
            if (this.CamposTela.itiReferencia) {
                this.headers.push({ text: this.CamposTela.itiReferenciaLabel, value: "itiReferencia", sortable: true, });
            }
            if (this.CamposTela.itiVisivel) {
                this.headers.push({ text: this.CamposTela.itiVisivelLabel, value: "itiVisivel", sortable: true, });
            }
            if (this.CamposTela.itiOrdem) {
                this.headers.push({ text: this.CamposTela.itiOrdemLabel, value: "itiOrdem", sortable: true, });
            }
            if (this.CamposTela.itiDestaque) {
                this.headers.push({ text: this.CamposTela.itiDestaqueLabel, value: "itiDestaque", sortable: true, });
            }
            if (this.CamposTela.itiDestaqueExtra) {
                this.headers.push({ text: this.CamposTela.itiDestaqueExtraLabel, value: "itiDestaqueExtra", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra1) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra1Label, value: "itiCampoExtra1", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra2) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra2Label, value: "itiCampoExtra2", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra3) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra3Label, value: "itiCampoExtra3", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra4) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra4Label, value: "itiCampoExtra4", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra5) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra5Label, value: "itiCampoExtra5", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra6) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra6Label, value: "itiCampoExtra6", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra7) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra7Label, value: "itiCampoExtra7", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra8) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra8Label, value: "itiCampoExtra8", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra9) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra9Label, value: "itiCampoExtra9", sortable: true, });
            }
            if (this.CamposTela.itiCampoExtra10) {
                this.headers.push({ text: this.CamposTela.itiCampoExtra10Label, value: "itiCampoExtra10", sortable: true, });
            }
            if (this.CamposTela.itiCor1) {
                this.headers.push({ text: this.CamposTela.itiCor1Label, value: "itiCor1", sortable: true, });
            }
            if (this.CamposTela.itiCor2) {
                this.headers.push({ text: this.CamposTela.itiCor2Label, value: "itiCor2", sortable: true, });
            }
            if (this.CamposTela.itiCor3) {
                this.headers.push({ text: this.CamposTela.itiCor3Label, value: "itiCor3", sortable: true, });
            }
            if (this.CamposTela.itiCor4) {
                this.headers.push({ text: this.CamposTela.itiCor4Label, value: "itiCor4", sortable: true, });
            }
            if (this.CamposTela.itiValor1) {
                this.headers.push({ text: this.CamposTela.itiValor1Label, value: "itiValor1", sortable: true, });
            }
            if (this.CamposTela.itiValor2) {
                this.headers.push({ text: this.CamposTela.itiValor2Label, value: "itiValor2", sortable: true, });
            }
            if (this.CamposTela.itiValor3) {
                this.headers.push({ text: this.CamposTela.itiValor3Label, value: "itiValor3", sortable: true, });
            }
            if (this.CamposTela.itiValor4) {
                this.headers.push({ text: this.CamposTela.itiValor4Label, value: "itiValor4", sortable: true, });
            }
            if (this.CamposTela.itiData1) {
                this.headers.push({ text: this.CamposTela.itiData1Label, value: "itiData1", sortable: true, });
            }
            if (this.CamposTela.itiData2) {
                this.headers.push({ text: this.CamposTela.itiData2Label, value: "itiData2", sortable: true, });
            }
            if (this.CamposTela.itiData3) {
                this.headers.push({ text: this.CamposTela.itiData3Label, value: "itiData3", sortable: true, });
            }
            if (this.CamposTela.itiData4) {
                this.headers.push({ text: this.CamposTela.itiData4Label, value: "itiData4", sortable: true, });
            }
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        AddSegmentos(value){
                if (this.InstitucionalSegs.sgiId == '' || this.InstitucionalSegs.sgiId == 0) {
                    this.$mensagemAviso("Campo Segmento Obrigatório.")
                }
                else{
                    if (this.InstitucionalSegs.issOrdem === "" || this.InstitucionalSegs.issOrdem === null) {
                        this.InstitucionalSegs.issOrdem = 0;
                    }
                    else {
                        this.InstitucionalSegs.issOrdem = parseInt(this.InstitucionalSegs.issOrdem);
                    }
                        this.InstitucionalSegs.insInstitucional = {ItiId: value};
                        this.InstitucionalSegs.InsSegmentos = {SgiId: this.InstitucionalSegs.sgiId};
                        axios.post(`/Process/InsInstitucionalSegmentos/Add`, this.InstitucionalSegs)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Segmento Incluído.")
                                this.CarregaListaSegmentos();
                            }
                        });
                }
        },
        CarregaSegmentos(){
            axios.get(`/Process/InsInstitucionalSegmentos/GetByItiId/${this.Institucional.itiId}`)
                    .then(response => {
                        this.ListaSegmentos = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.issOrdem) || 0;
                                                                                const valorB = parseInt(b.issOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                    this.ListaSegmentos =  this.itensComHierarquia(this.ListaSegmentos);  
                    console.log('this.ListaSegmentos')
                    console.log(this.ListaSegmentos)
                    console.log('this.ListaHierarquiaSeg')
                    console.log(this.ListaHierarquiaSeg)

                    });
        },
        CarregaListaSegmentos(){
            axios.get(`/Process/InsSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosInstGeral = response.data;
                                    this.ListaHierarquiaSeg = this.ListSegmentosInstGeral.filter(x=> x.sgiVisivel && x.ctiId == this.id && x.idiId == this.Institucional.idiId);
        //------ carrega select sgiIdPai com hierarquia
        function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
            const segmentosFilhos = segmentos.filter(segmento => segmento.sgiIdPai === segmentoIdPai);
            const segmentosComCaminho = [];

            for (const segmentoFilho of segmentosFilhos) {
                const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.sgiNomeSegmento : `${caminhoAtual} > ${segmentoFilho.sgiNomeSegmento}`;

                segmentosComCaminho.push({ sgiId: segmentoFilho.sgiId, sgiNomeSegmento: caminhoCompleto });
                
                const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.sgiId, caminhoCompleto);
                segmentosComCaminho.push(...segmentosNetos);
            }

            return segmentosComCaminho;
            }
        
            const segmentosComCaminho = [];
            for (const segmento of this.ListaHierarquiaSeg.filter(x=> x.sgiIdPai== 0)) {
            segmentosComCaminho.push({ sgiId: segmento.sgiId, sgiNomeSegmento: segmento.sgiNomeSegmento });
            
            const segmentosFilhos = buildHierarchy(this.ListaHierarquiaSeg, segmento.sgiId, segmento.sgiNomeSegmento);
            segmentosComCaminho.push(...segmentosFilhos);
            }
            this.ListaHierarquiaSeg = segmentosComCaminho;   
            this.CarregaSegmentos();
        //------
        });
        },
        itensComHierarquia(itens) {
        // Adicione a lógica para adicionar a coluna "Hierarquia" ao array de dados
        const itensComHierarquia = itens.map((item) => {
            const hierarquia = this.ListaHierarquiaSeg.filter(
            (x) => x.sgiId === item.insSegmentos.sgiId
            )[0]?.sgiNomeSegmento || "";

            return { ...item, Hierarquia: hierarquia };
        });

          return itensComHierarquia;
        },
        DeleteSegmentos(item){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.issId;
                axios.delete(`/Process/InsInstitucionalSegmentos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaListaSegmentos();
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/AdmConfigTelaInstitucional/GetByCtiId/${this.id}`)
                .then(response => {
                    if (response.data != '') {
                        this.CamposTela = response.data;
                        axios.get(`/Process/CadIdiomas/GetAll`)
                            .then(response => {
                                this.Idiomas = response.data.filter(x => x.idiAtivo);
                                if (this.Idiomas.length == 1) {
                                    this.Institucional.idiId = this.Idiomas[0].idiId;
                                    this.vshowidioma = false;
                                }
                                    axios.get(`/Process/InsInstitucional/GetAllByCtiId/${this.id}`)
                                        .then(response => {
                                            this.ListInstitucionalGeral = response.data;
                                            this.ListInstitucional = this.ListInstitucionalGeral.filter(x => x.idiId == this.Institucional.idiId)
                                            this.montaGrid();
                                        });

                                    axios.get(`Process/AdmCadastroTelasInst/GetById/${this.id}`)
                                        .then(response => {
                                            this.CadastroTelas = response.data;
                                            if (this.CadastroTelas != '') {
                                                this.Institucional.ctiId = this.CadastroTelas.ctiId;
                                                this.Institucional.gtiId = this.CadastroTelas.admGrupoTelasInst.gtiId;
                                                this.NomeTela = this.CadastroTelas.ctiNome;
                                                if (this.CamposTela.multi){
                                                    this.InfoTela = "Tipo: Lista de informações";
                                                }
                                                else{
                                                    this.InfoTela = "Tipo: Informação única";
                                                }
                                               
                                            }
                                        });

                                
                            });
                    }
                });

        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
