<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="">
                        <div class="clearfix" style="padding-bottom: 30px;">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                        <div class="row" style="padding-top: 20px;">
                            <div class="col-lg-12 mb-lg-0" style="text-align:center;padding-bottom: 30px;">
                                <h3>Ordem e distribuicao de avulsos por cestas</h3>
                            </div>
                            <div class="col-lg-9" style="text-align:center;">
                                <v-select v-model="Listaselected" label="Avulsos" :items="Lista"
                                    @update:modelValue="MudaLista(Listaselected)" item-title="praNomeProduto" item-value="praId"
                                    variant="underlined">
                                </v-select>
                            </div>
                            <div class="col-lg-3" style="text-align:center;">
                                <button class="btn btn-success" @click="CarregaDados()">Listar</button>
                            </div>
                        </div>
                        <div class="row" v-if="groups!=''">
                            <div class="col-lg-10">
                                <div class="row">
                                    <div class="col-6">
                                    <h3>{{groups[0].name }}</h3>
                                    <draggable
                                        class="list-group"
                                        :list="groups[0].items"
                                        group="people"
                                        @change="log"
                                        itemKey="name"
                                    >
                                        <template #item="{ element, index }">
                                            <div class="list-group-item">{{ element.produto.proNomeProduto }} {{ index }}</div>
                                        </template>
                                    </draggable>
                                    </div>

                                    <div class="col-6">
                                    <h3>{{groups[1].name }}</h3>
                                    <draggable
                                        class="list-group"
                                        :list="groups[1].items"
                                        group="people"
                                        @change="log"
                                        itemKey="name"
                                    >
                                        <template #item="{ element, index }">
                                        <div class="list-group-item">{{ element.produto.proNomeProduto }} {{ index }}</div>
                                        </template>
                                    </draggable>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <button class="btn btn-warning" style="margin-top:70px;" @click="SalvaDados()">Salvar Resultado</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import draggable from 'vuedraggable'

export default {
    name: 'ProAvulsosporCestasView',
    components: {
        moment,
        draggable
    },
    data: () => ({
        itemsSelected: [],
        NomeTela: 'Avulsos por Cestas',
        InfoTela: 'Lista de produtos',
        MostraGrid: true,
        headers: [],
        Idiomas: [],
        vshowidioma: true,
        CadastroTelas: [{ cdtId: 33, cdtNome: "Avulsos por Cestas" }],
        ListaId: 0,
        Lista: [],
        Listaselected: null,
        ProdutosAvulso: [],
        Produtos: [],
        resultado: {},
        groups: [],
        options: {
            dropzoneSelector: ".drag-inner-list",
            draggableSelector: ".drag-item"
        }
    }),
    methods: {
        handleSlotContent({ elementProps }) {
      if (elementProps) {
        // Faça o que deseja com elementProps aqui
        // Por exemplo, desestruture elementProps ou utilize-o diretamente
      } else {
        // Lógica alternativa caso elementProps seja indefinido
      }
    },
        MudaLista(value) {
            this.ListaId = value.praId;
        },
        onGroupsChange(e) {
            console.log({ e });
            this.resultado = e;
        },
        SalvaDados() {
          console.log("groups[1].items")
          console.log(this.groups[1].items)

        },
        CarregaDados() {
            if (this.Listaselected != null) {
                this.groups = []
                let vcount = 0;

                //carrega lista de todos produtos
                axios.get(`/Process/ProProdutos/GetAllByProdutos`) //GetAllByCtiId/14
                    .then(response => {
                        this.Produtos = response.data.filter(x => x.proVisivel);
                        console.log('Produtos')
                        console.log(this.Produtos)

                        //carrega produtos do avulso selecionado
                        axios.get(`/Process/ProAvulsosporProdutos/GetAll`) //GetAllByCtiId/14
                            .then(response => {
                                this.ProdutosAvulso = response.data.filter(x => x.proProdutosAvulso.praId == this.Listaselected);
                                console.log('ProdutosAvulso')
                                console.log(this.ProdutosAvulso)

                                this.groups.push({ id: 1, name: "Lista de Cestas", items: [] })

                                this.Produtos.forEach((itemex) => {
                                    vcount++
                                    let recebe = this.ProdutosAvulso.filter(x => x.proProdutos.proId == itemex.proId);
                                    if (recebe == '') {
                                        this.groups[0].items.push({ id: vcount, produto: itemex, groupId: 1 })
                                    }

                                });

                                this.groups.push({ id: 3, name: "Cesta que contém Avulso", items: [] })
                                //console.log(this.ProdutosAvulso)
                                this.ProdutosAvulso.forEach((itemex) => {
                                    vcount++
                                    this.groups[1].items.push({ id: vcount, produto: itemex.proProdutos, groupId: 3 })
                                });
                            });
                        console.log('this.groups')
                        console.log(this.groups)
                    });
            } else {
                this.$mensagemAvisoSucesso("Você deve selecionar Produto Avulso.");
            }
        },
        Get() {
            axios.get(`/Process/ProProdutosAvulsos/GetAll`) //GetAllByCtiId/16
                .then(response => {
                    this.Lista = response.data;
                    console.log('Lista')
                    console.log(this.Lista)
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.drag-container {
    max-width: 1000px;
    margin: 20px auto;
}

.drag-list {
    display: flex;
    align-items: flex-start;
}

.drag-column {
    flex: 1;
    margin: 0 10px;
    position: relative;
    background: rgba(black, 0.2);
    overflow: hidden;
}

.drag-column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    user-select: none;
}

.drag-inner-list {
    height: 85vh;
    overflow: auto;
}

.drag-item {
    margin: 5px;
    height: 60px;
    background: #f2f2f2;
    transition: ease-out;
}

.drag-item-text {
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
}


.drag-header-more {
    cursor: pointer;
}

@keyframes nodeInserted {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 0.8;
    }
}

.item-dropzone-area {
    height: 6rem;
    background: #888;
    opacity: 0.8;
    animation-duration: 0.5s;
    animation-name: nodeInserted;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
}

@media (max-width:600px) {

    .drag-item {
        margin: 2px;
        height: 40px;
        background: #f2f2f2;
        transition: ease-out;
    }

    .drag-item-text {
        font-size: 12px;
        padding-left: 1rem;
        padding-top: 3px;
        line-height: 1.1;
    }

    .hidden-xs {
        display: none !important;
    }

    .item-dropzone-area {
        height: 3rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }

    .drag-inner-list {
        height: 55vh;
        overflow: auto;
    }
}
</style>
