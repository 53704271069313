<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7">
                        <v-tab value="dados" class="tabinfo">Detalhe Cotação</v-tab>
                        <v-tab value="impressao" class="tabinfo" style="display: none;">Impressão Cotação</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container
                                    v-if="PedidosCli != '' && PedidosEndereco != ''">
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 15px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Endereço Entrega</h5>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;padding-bottom:15px;">
                                                                <strong>Tipo Frete:</strong> {{ PedidosCli.pedTipoFrete }}
                                                                <v-text-field v-model="PedidosCli.pedObsLoja" :disabled="PedidosCli.pspId >= 3" label="Transporte" variant="underlined" type="text"></v-text-field>
                                                            </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                               {{ PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} - {{ PedidosEndereco[0].pdcComplemento }} </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                               {{ PedidosEndereco[0].pdcCidade }} / {{ PedidosEndereco[0].pdcEstado}}</p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                {{ PedidosEndereco[0].pdcBairro }} </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Obs</strong> {{ PedidosCli.pedObsCliente }}
                                                            </p>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Situação
                                                            </h5>
                                                            <v-row>
                                                                <v-select v-model="PedidosCli.pspId" label="Situação Atual" :disabled="mostrasit"
                                                                    :items="Situacoes" item-title="pspSituacao"
                                                                    item-value="pspId" variant="underlined">
                                                                </v-select>
                                                            </v-row>
                                                            <v-row style="justify-content: center;padding-top: 15px;">
                                                                <button type="button" class="btn btn-success" :disabled="mostrasit"
                                                                    @click="PutSituacao()">
                                                                    Alterar
                                                                </button>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Forma de Pagamento
                                                            </h5>
                                                            <v-text-field v-model="PedidosCli.pedFormaPgto" :disabled="PedidosCli.pspId >= 3" label="Parcelamento" variant="underlined" type="text"></v-text-field>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <button type="button" class="btn btn-success" :disabled="PedidosCli.pspId >= 3"  @click="PutPedidoItens()">
                                                            Salvar Valores e Parcelamento
                                                        </button>
                                                    </v-card>
                                            </v-col>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 15px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Informações da Cotação</h5>
                                                            <h6 class="mb-0">Cotação: {{ PedidosCli.pedId }}</h6> 
                                                            <div class="pedido_dados"><strong>Data:</strong> {{ formattedDate(PedidosCli.pedDataPedido) }}</div>
                                                            <div class="pedido_dados"><strong>Cliente:</strong> {{ Cliente.cliNomeEmpresa }} - {{ Cliente.cliEmail }} - {{ Cliente.cliWhatsapp }}</div>
                                                            <div class="pedido_dados" style="display:inline-flex;">
                                                                <v-select v-model="PedidosCli.pedUser" :disabled="PedidosCli.pspId >= 3" label="Atendimento Comercial" style="width: 350px;padding-right: 20px;"
                                                                    :items="UsuariosVend" item-title="cusNome"
                                                                    item-value="cusId" variant="underlined">
                                                                </v-select>
                                                                <a href="#" class="btn btn-sm btn-info" :disabled="PedidosCli.pspId >= 3" style="padding:3px !important;height: 30px;margin-top: 15px;" @click="PutUserVend()">
                                                                    Direcionar Comercial
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="ProdRefer" :disabled="PedidosCli.pspId >= 3" label="Descrição" variant="underlined" type="text"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <button type="button" class="btn btn-success" :disabled="PedidosCli.pspId >= 3"  @click="AddPedidoItens()">
                                                        Incluir Item
                                                    </button>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f9f9f9">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Itens da Cotação</h5> 
                                                            <div v-for="produto in ListaPedidoItem" :key="produto.pdiId"
                                                                class="descricao-pedido border-top py-3">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                        <div class="descricao-img">
                                                                            <img v-if="produto.proProdutos != '' && produto.proProdutos.proCampoExtra10 != '' && produto.proProdutos.proCampoExtra10 != null"
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + produto.proProdutos.proCampoExtra10"
                                                                                alt="">
                                                                           <img v-else-if="produto.proProdutos != '' && produto.proProdutos.proCampoExtra7 != '' && produto.proProdutos.proCampoExtra7 != null"
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="produto.proProdutos.proCampoExtra7"
                                                                                alt="">
                                                                            <img v-else
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + '/imagens/indisponivel.jpg'"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-4 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{ produto.pdiNomeProduto
                                                                            }}</h6>
                                                                            <p class="texto-mensagem"> Código: {{
                                                                                produto.proId }}</p>
                                                                            <p class="texto-mensagem"> Referência: {{
                                                                                produto.proProdutos.proReferencia }} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo"> 
                                                                                <v-text-field v-model="produto.pdiQtdeProduto" :disabled="PedidosCli.pspId >= 3" label="Qtde" variant="underlined" type="number"></v-text-field></h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                <v-text-field v-model="produto.pdiValorProduto" :disabled="PedidosCli.pspId >= 3" label="R$" variant="underlined" type="number"></v-text-field> 
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                R$ {{
                                                                                    calcularTotalItem(produto).toFixed(2).replace('.', ',')
                                                                                }}</h6>
                                                                        </div>
                                                                        <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir" style="padding-left: 5px;"
                                                                            @click.prevent="ExcluiProduto(produto.pdiId)">
                                                                            <i class="fa fa-window-close"
                                                                                style="color: black;font-size: 13px;"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-for="adicional in ListaPedidoItemAdicional"
                                                                :key="adicional.piaId"
                                                                class="descricao-pedido border-top py-3">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                        <div class="descricao-img">
                                                                            <img v-if="adicional.proProdutosAvulsos.proProdutosAvulsosImagens != null"
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                style="width:80%;"
                                                                                :src="$imgURL + adicional.proProdutosAvulsos.proProdutosAvulsosImagens[0].paiUrlImagem"
                                                                                alt="">
                                                                            <img v-else
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                style="width:70%;"
                                                                                :src="$imgURL + '/imagens/indisponivel.jpg'"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-5 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{
                                                                                adicional.piaNomeProduto }}</h6>
                                                                            <p class="texto-mensagem"> Código: {{
                                                                                adicional.proProdutosAvulsos.praReferencia }}
                                                                            </p>
                                                                            <small v-if="adicional.proProdutosAvulsos.praCampoExtra1!=''" class="texto-mensagem" style="font-size: 12px;"> <strong>Texto:</strong> {{
                                                                                adicional.proProdutosAvulsos.praCampoExtra1 }}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-1 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{
                                                                                adicional.piaQtdeProduto }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                R$ {{
                                                                                    parseFloat(adicional.piaValorProduto).toFixed(2).toString().replace('.', ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                R$ {{
                                                                                    parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.', ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Desconto</h5>
                                                                <h6 class="subtitulo"> 
                                                                    <v-text-field v-model="PedidosCli.pedValorCupom" :disabled="PedidosCli.pspId >= 3" label="(-) R$" variant="underlined" type="number"></v-text-field>
                                                                </h6>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Frete</h5>
                                                                <h6 class="subtitulo">
                                                                    <v-text-field v-model="PedidosCli.pedValorFrete" :disabled="PedidosCli.pspId >= 3" label="R$" variant="underlined" type="number"></v-text-field>
                                                                 </h6>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between" style="padding-top:20px;">
                                                                <h5 class="subtitulo">Total</h5>
                                                                <h4 class="subtitulo">R$ {{ totalGeral.toFixed(2).replace('.', ',') }}</h4>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="impressao">
                            <div ref="tabela" class="sua-tabela-classe">
                                <v-table style="padding-top: 20px;font-size: 18px;"
                                    v-if="PedidosCli != '' && PedidosAgendamento != '' && PedidosEndereco != '' && Cidades != '' && Estados != '' && Bairros != '' && PedidosMensagem.length > 0">
                                    <tr>
                                        <td style="width: 70%;border: none;">
                                            <v-table>
                                    <tr>
                                        <td><strong>Pedido:</strong> {{ PedidosCli.pedId }}</td>
                                        <td><strong>Impresso: {{ PedidosCli.pedObsLoja == null ? 'Não' : PedidosCli.pedObsLoja }}</strong> </td>
                                        <td style="text-align: center;"><strong>Data</strong></td>
                                        <td> {{ formattedDate(PedidosCli.pedDataPedido) }}</td>
                                        <td><strong>Status Pagamento</strong></td>
                                        <td>{{ PedidosCli.pedSituacaoPedido }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Cliente</strong></td>
                                        <td colspan="3"> {{ Cliente.cliNomeEmpresa }}</td>
                                        <td><strong>Whatsapp</strong></td>
                                        <td>{{ Cliente.cliWhatsapp }}</td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td>Ref</td>
                                        <td colspan="3">Produto</td>
                                        <td>Qtde</td>
                                        <td>Valor</td>
                                    </tr>
                                    <tr v-for="produto in ListaPedidoItem" :key="produto.pdiId">
                                        <td> {{ produto.proProdutos.proReferencia }}</td>
                                        <td colspan="3">
                                            <h6 class="subtitulo">{{ produto.pdiNomeProduto }}</h6>
                                        </td>
                                        <td>{{ produto.pdiQtdeProduto }}</td>
                                        <td>R$ {{
                                            parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr v-for="adicional in ListaPedidoItemAdicional" :key="adicional.piaId">
                                        <td> {{ adicional.proProdutosAvulsos.praReferencia }}</td>
                                        <td colspan="3">
                                            <h6 class="subtitulo">{{ adicional.piaNomeProduto }}</h6>
                                            <small style="font-size:11px;">{{adicional.piaCampoExtra1 }}</small>
                                        </td>
                                        <td>{{ adicional.piaQtdeProduto }}</td>
                                        <td>R$ {{
                                            parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr style="height: 30px;">
                                        <td></td>
                                        <td colspan="3">
                                            <h6 class="subtitulo"></h6>
                                        </td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr style="height: 30px;">
                                        <td></td>
                                        <td colspan="3">
                                            <h6 class="subtitulo"></h6>
                                        </td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="2">Forma Pagamento</td>
                                        <td>Desconto</td>
                                        <td>Frete</td>
                                        <td colspan="2">Valor total</td>
                                    </tr>
                                    <tr style="text-align: center;">
                                        <td colspan="2">
                                            <div class="pedido_dados" v-if="PedidosCli.pedFormaPgto == 'credit_card'">Cartão
                                                Crédito</div>
                                            <div class="pedido_dados" v-else-if="PedidosCli.pedFormaPgto == 'bank_transfer'">PIX
                                            </div>
                                            <div class="pedido_dados" v-else>{{ PedidosCli.pedFormaPgto }}</div>
                                        </td>
                                        <td>
                                            R$ {{ parseFloat(PedidosCli.pedValorCupom).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                        <td>
                                            R$ {{ parseFloat(PedidosCli.pedValorFrete).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                        <td colspan="2">
                                            R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="6"> Dados de Entrega</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6">
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Quem Recebe:
                                                </strong>{{ PedidosMensagem[0].pmsNomePara }} -
                                                {{ PedidosMensagem[0].pmsTelefoneRecebe }}</p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Endereço:</strong> {{
                                                PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} - {{
            PedidosEndereco[0].pdcComplemento }} </p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Cidade/UF:</strong> {{
                                                Cidades.filter(x => x.cidId == PedidosEndereco[0].cidId)[0].cidNome }} / {{
            Estados.filter(x => x.cetId == PedidosEndereco[0].cetId)[0].cetSigla }}</p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Bairro:</strong> {{
                                                PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                    PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro }} </p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Entrega Dia:</strong>
                                                {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre {{
                                                    validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{
            validaHora(PedidosAgendamento[0].pagMinIni) }} e {{
            validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{
            validaHora(PedidosAgendamento[0].pagMinFim) }} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" style="height: 50px;">
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Obs Cliente</strong>
                                                {{ PedidosCli.pedObsCliente }}</p>
                                        </td>
                                    </tr>
                                </v-table>
                                </td>
                                <td style="width: 70%;vertical-align: top;border: none;height: 100%;">
                                    <v-table>
                                        <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                            <td colspan="2">Comanda Entrega</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Pedido:</strong></td>
                                            <td>{{ PedidosCli.pedId }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Quem Recebe:
                                                    </strong>{{ PedidosMensagem[0].pmsNomePara }} -
                                                    {{ PedidosMensagem[0].pmsTelefoneRecebe }}</p>
                                                <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Endereço:</strong>
                                                    {{ PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} -
                                                    {{ PedidosEndereco[0].pdcComplemento }} </p>
                                                <p class="texto-mensagem mb-0" style="color:#333333;">
                                                    <strong>Cidade/UF:</strong> {{ Cidades.filter(x => x.cidId ==
                                                        PedidosEndereco[0].cidId)[0].cidNome }} / {{ Estados.filter(x => x.cetId ==
            PedidosEndereco[0].cetId)[0].cetSigla }}</p>
                                                <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Bairro:</strong>
                                                    {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                        PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro }} </p>
                                                <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Entrega
                                                        Dia:</strong> {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }}
                                                    entre {{ validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{
                                                        validaHora(PedidosAgendamento[0].pagMinIni) }} e {{
            validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{
            validaHora(PedidosAgendamento[0].pagMinFim) }} </p>
                                            </td>
                                        </tr>
                                        <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                            <td colspan="2">Assinatura Recebe</td>
                                        </tr>
                                        <tr v-if="PedidosCli.pedFormaPgto == 'credit_card'">
                                            <td colspan="2" style="height: 120px;"></td>
                                        </tr>
                                        <tr v-else>
                                            <td colspan="2" style="height: 200px;"></td>
                                        </tr>
                                        <tr v-if="PedidosCli.pedFormaPgto == 'credit_card'" style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                            <td colspan="2">Documento</td>
                                        </tr>
                                        <tr v-if="PedidosCli.pedFormaPgto == 'credit_card'">
                                            <td colspan="2" style="height: 140px;"></td>
                                        </tr>
                                    </v-table>
                                </td>
                                </tr>
                                </v-table>
                            </div>
                            <center><button @click="gerarPDF" class="btn btn-success" style="margin-top: 30px;">Gerar PDF</button></center>
                        </v-window-item>
                    </v-window>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import { useRoute } from 'vue-router';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
    name: 'ListaPedidosView',
    components: {
        EasyDataTable,
        moment
    },
    setup() {
        const route = useRoute();
        const id = route.params.idPed;

        return {
            id,
            };
    },
    data: () => ({
        tab: null,
        NomeTela: 'Cotações',
        InfoTela: 'Detalhe Cotação',
        MostraGrid: true,
        Cliente: [],
        headers: [],
        Idiomas: [],
        vshowidioma: true,
        CadastroTelas: [{ cdtId: 28, cdtNome: "Detelhe Cotação" }],
        PedidosCli: [],
        Pedidos: [],
        TotalPedido: 0,
        validacomprova: [],
        PedidosComprova: [],
        showModal_comprova: false,
        ListaPedidoItem: [],
        ListaPedidoItemAdicional: [],
        PedidosMensagem: [],
        PedidosAgendamento: [],
        PedidosEndereco: [],
        Estados: [],
        Cidades: [],
        Bairros: [],
        selectedFile: '',
        urlComprovante: '',
        PedidoComprovante: [],
        Situacoes: [],
        totalGeral:0,
        ProdRefer:'',
        ProdItem:[],
        UsuariosVend:[],
        mostrasit:false,
    }),
    computed: {
        totalGeral() {
        var valor = this.ListaPedidoItem.reduce((total, produto) => {
              return total + this.calcularTotalItem(produto);
      }, 0);

      return ((valor - this.PedidosCli.pedValorCupom) + parseFloat(this.PedidosCli.pedValorFrete));
    },
  },
    methods: {
        PutUserVend(){

            if (this.UsuariosVend ==null || this.UsuariosVend == "")
            {
                this.$mensagemAvisoSucesso("Selecione um Comercial para atendimento.")
            }
            else{
                axios.put(`/Process/PedPedidos/UpdatePedUser/` + this.PedidosCli.pedId + '/' + this.PedidosCli.pedUser)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Comercial adicionado.")
                        //this.EnviaAviso(_situacao);
                    }
                });
            }
          
        },
        AddPedidoItens(){
          if (this.ProdRefer == null || this.ProdRefer == ""){
            this.$mensagemAviso('Preencha Referência')
          }else{
            axios.get(`/Process/ProProdutos/GetByRef/` + this.ProdRefer)
                .then(response => {
                    this.ProdItem = response.data;
                    console.log('this.ProdItem')
                    console.log(this.ProdItem)
                    if (this.ProdItem!="")
                    {
                    let pedItem = {
                        PdiId: 0,
                        GrtId: 7,
                        CdtId: 28,
                        IdiId: 1,
                        PedId: this.PedidosCli.pedId,
                        ProId: this.ProdItem.proId,
                        PdiNomeProduto: this.ProdItem.proNomeProduto,
                        PdiQtdeProduto: 1,
                        PdiValorProduto: 0,
                        PdiValorTotalProduto:0,
                        PdiDataCadastro: moment().format('YYYY-MM-DD')
                    }
                    axios.post(`/Process/PedPedidoItem/Add`, pedItem)
                        .then(response => {
                            if (response.data > 0) {
                                    this.$mensagemAvisoSucesso("Registro Incluído. aba imagens liberada.")
                                    this.ProdRefer = "";
                                    this.ProdItem = [];
                                    this.Get();
                            }
                        });
                    }
                    else{
                        this.$mensagemAvisoSucesso("Produto não encontrado.")
                    }

                });
          }
        },
        ExcluiProduto(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value;
                axios.delete(`/Process/PedPedidoItem/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                    }
                });
            }
            });
        },
        async gerarPDF() {
            await this.$nextTick();
            const pdf = new jsPDF({
                orientation: 'portrait', // Retrato (p)
                unit: 'mm', // Unidade de medida
                format: 'a4', // Formato de página A4
            });

            const tabela = this.$refs.tabela; // Substitua 'tabela' pelo ref correto da sua tabela

            // Capture a tabela como uma imagem
            const canvas = await html2canvas(tabela);

            // Calcule a largura e altura da página A4 em mm
            const larguraA4 = 210;
            const alturaA4 = 297;

            // Calcule a escala para ajustar o conteúdo à página A4
            const escala = Math.min(larguraA4 / canvas.width, alturaA4 / canvas.height);

            // Defina as dimensões da imagem da tabela com base na escala
            const larguraImagem = canvas.width * escala;
            const alturaImagem = canvas.height * escala;

            // Adicione a imagem ao PDF e ajuste as dimensões
            pdf.addImage(canvas, 'PNG', 0, 0, larguraImagem, alturaImagem);


             // Imprima o PDF diretamente
             //pdf.autoPrint();

             //pdf.autoPrint({ variant: 'non-conform' });
             window.open(pdf.output('bloburl'), '_blank');
            // Salve ou exiba o PDF
            //pdf.save(`Pedido_${this.PedidosCli.pedId}.pdf`);
           
            this.PutImpressao('Sim');
            // Configuração para imprimir sem diálogo de impressão
            //pdf.output('dataurlnewwindow');
            
            },
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        validaHora(value) {
            var _hora = value.toString();
            if (_hora.length == 1)
                _hora = "0" + _hora;
            return _hora;
        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;


            if (this.ListaPedidoItem != '') {
                this.ListaPedidoItem.forEach(item => {
                    valorProdutos += item.pdiValorTotalProduto;
                });
            }

            if (this.ListaPedidoItemAdicional != '') {
                this.ListaPedidoItemAdicional.forEach(item => {
                    valorAvulsos += item.piaValorTotalProduto;
                });
            }

            this.TotalPedido = (valorProdutos + valorAvulsos + parseFloat(this.PedidosCli.pedValorFrete)) - parseFloat(this.PedidosCli.pedValorCupom);
        },
        async PutSituacao() {
            if (this.PedidosCli.pspId == 3){
                this.$mensagemConfirma('Ao aprovar pedido não poderá ser alterado.')
                .then((result) => {
                    if (result.isConfirmed) {

                        var _situacao = this.Situacoes.filter(x => x.pspId == this.PedidosCli.pspId)[0].pspSituacao;
                        axios.put(`/Process/PedPedidos/UpdateSituacao/` + this.PedidosCli.pedId + '/' + this.PedidosCli.pspId + '/' + _situacao)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Alterado.")
                                    this.EnviaAvisoAprovado(_situacao);
                                    this.mostrasit = true;
                                }
                            });
                        //enviar email
                    }
                });
            }
            else{
                var _situacao = this.Situacoes.filter(x => x.pspId == this.PedidosCli.pspId)[0].pspSituacao;
                        axios.put(`/Process/PedPedidos/UpdateSituacao/` + this.PedidosCli.pedId + '/' + this.PedidosCli.pspId + '/' + _situacao)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Alterado.")
                                    this.EnviaAviso(_situacao);
                                    this.$router.push('/ListaPedidos/');
                                }
                            });
                        //enviar email
            }
           
        },
        async PutImpressao(value) {
            axios.put(`/Process/PedPedidos/UpdateImpressao/` + this.PedidosCli.pedId + '/' + value)
                .then(response => {
                    if (response.status == 200) {
                        console.log("impresso")
                    }
                });
            //enviar email
        },
        EnviaAviso(_situacao) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 3);

                    let _dadosEmail = {
                        nomeDe: 'Corofergs ',
                        nomePara: this.Cliente.cliNomeEmpresa,
                        assunto: 'Andamento Pedido: ' + this.PedidosCli.pedId,
                        destinatario: this.Cliente.cliEmail,
                        emailResposta: 'vendas@corofergs.com.br',
                        textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa).replace('[NumeroPedido]', this.PedidosCli.pedId).replace('[Status]', _situacao),
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        async EnviaAvisoAprovado(_situacao) {
            let dadosPedidos = "<h2><strong>Dados do Pedido</strong></h2><br/><br/>";

            dadosPedidos += " <strong>Cotação: </strong>" + this.PedidosCli.pedId + "<br/>";
            dadosPedidos += " <strong>Situação: </strong>" + _situacao + "<br/>";
            dadosPedidos += " <strong>Data: </strong>" + this.formattedDate(this.PedidosCli.pedDataPedido) + "<br/><br/>";

            dadosPedidos += " <strong>Entrega: </strong><br/><br/>";
            dadosPedidos += " <strong>Tipo Frete: </strong>" + this.PedidosCli.pedTipoFrete + " - " + this.PedidosCli.pedObsLoja + "<br/>";;
            dadosPedidos += " <strong>Endereço: </strong>" + this.PedidosEndereco[0].pdcEndereco + " - " + this.PedidosEndereco[0].pdcNumero + " - " + this.PedidosEndereco[0].pdcComplemento + "<br/>";
            dadosPedidos += " <strong>Cidade/UF: </strong>" + this.PedidosEndereco[0].pdcCidade + " - " + this.PedidosEndereco[0].pdcEstado + "<br/>";
            dadosPedidos += " <strong>Bairro: </strong>" + this.PedidosEndereco[0].pdcBairro == '' ? this.Bairros.filter(x => x.baiId == this.PedidosEndereco[0].baiId)[0].baiNome : this.PedidosEndereco[0].pdcBairro + " - " + this.PedidosEndereco[0].pdcEstado + "<br/>";
            dadosPedidos += " <strong>CEP: </strong>" + this.PedidosEndereco[0].pdcCep + "<br/>";
            dadosPedidos += " <strong>Obs Cliente: </strong>" + this.PedidosCli.pedObsCliente + "<br/><br/><br/>";


            dadosPedidos += " <strong>Itens do Pedido:</strong><br><br>"
            this.ListaPedidoItem.forEach(element => {
                dadosPedidos += "<strong>Nome: </strong>" + element.pdiNomeProduto + " | "
                    + "<strong>Referência: </strong>" + element.proProdutos.proReferencia + " | "
                    + "<strong>Qtde: </strong>" + element.pdiQtdeProduto + " | "
                    + "<strong>Valor: </strong> R$ " + parseFloat(element.pdiValorProduto).toFixed(2).toString().replace('.', ',') + " | "
                    + "<strong>Total: </strong> R$ " + parseFloat(element.pdiValorTotalProduto).toFixed(2).toString().replace('.', ',') + " | <br><br>";
            });


            dadosPedidos += " <strong>Desconto: </strong> R$ " + parseFloat(this.PedidosCli.pedValorCupom).toFixed(2).toString().replace('.', ',') + "<br/>";
            dadosPedidos += " <strong>Frete: </strong> R$ " + parseFloat(this.PedidosCli.pedValorFrete).toFixed(2).toString().replace('.', ',') + "<br/>";
            dadosPedidos += " <strong>Forma de Pgto: </strong>" + this.PedidosCli.pedFormaPgto + "<br/>";
            dadosPedidos += " <strong>Total Pedido: </strong> R$ " + parseFloat(this.TotalPedido).toFixed(2).toString().replace('.', ',') + "<br/>";

            await axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 3);
                });

            let _dadosEmail = {
                nomeDe: 'Corofergs',
                nomePara: this.Cliente.cliNomeEmpresa,
                assunto: 'Andamento Pedido: ' + this.PedidosCli.pedId,
                destinatario: this.Cliente.cliEmail,
                emailResposta: this.logintopo.pfcEmailRecebeAviso,
                textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa).replace('[NumeroPedido]', this.PedidosCli.pedId).replace('[Status]', _situacao) + "<br>" + dadosPedidos,
                nomeAnexo: '',
                anexo: '',
                tipoAnexo: ''
            }
            await axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                .then(response => {
                    if (response.data == 1) {
                        console.log('email enviado')
                    }
                });

            let _dadosEmail2 = {
                nomeDe: 'Corofergs',
                nomePara: 'Corofergs',
                assunto: 'Aprovação Pedido: ' + this.PedidosCli.pedId,
                destinatario: this.logintopo.pfcEmailRecebeAviso,
                emailResposta: this.logintopo.pfcEmailRecebeAviso,
                textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', 'Corofergs').replace('[NumeroPedido]', this.PedidosCli.pedId).replace('[Status]', _situacao) + "<br>" + dadosPedidos,
                nomeAnexo: '',
                anexo: '',
                tipoAnexo: ''
            }
            await axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail2)
                .then(response => {
                    if (response.data == 1) {
                        console.log('email 2 enviado')
                        this.$router.push('/ListaPedidos/');
                    }
                });

        },
        calcularTotalItem(produto) {
         return produto.pdiQtdeProduto * produto.pdiValorProduto;
        },
        async Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Pedidos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/PedPedidos/GetById/` + this.id)
                        .then(response => {
                            this.PedidosCli = response.data;
                            console.log('this.PedidosCli')
                            console.log(this.PedidosCli)
                            
                            if (this.PedidosCli.pspId == 3)
                            {
                                this.mostrasit = true;
                            }

                            axios.get(`/Process/CliClientes/GetById/` + this.PedidosCli.cliId)
                                .then(response => {
                                    this.Cliente = response.data;
                                    console.log('this.Cliente')
                                    console.log(this.Cliente)
                                   
                                });

                        });
                });

            await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItem = response.data;
                    console.log('ListaPedidoItem')
                    console.log(this.ListaPedidoItem)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItemAdicional = response.data;
                    console.log('ListaPedidoItemAdicional')
                    console.log(this.ListaPedidoItemAdicional)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoAgendamento/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosAgendamento = response.data;
                    console.log('PedidosAgendamento')
                    console.log(this.PedidosAgendamento)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosEndereco = response.data;
                    console.log('PedidosEndereco')
                    console.log(this.PedidosEndereco)
                })
                .catch(error => {
                    console.error(error);
                });


            axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data
                    console.log('this.Situacoes')
                    console.log(this.Situacoes)
                });

            axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidoComprovante = response.data.filter(x => x.ppcUrlImagem != '')
                    console.log('this.PedidoComprovante')
                    console.log(this.PedidoComprovante)
                });

            this.calculatotal();
        },
        async PutPedidoItens(){
            await this.ListaPedidoItem.forEach(element => {
              if (element.pdiQtdeProduto == "" || element.pdiQtdeProduto == null){
                element.pdiQtdeProduto = 0
              }
              if (element.pdiValorProduto == "" || element.pdiValorProduto == null){
                element.pdiValorProduto = 0
              }

              let pedItem = {
                PdiId: element.pdiId,
                GrtId: 7,
                CdtId: 28,
                IdiId: 1,
                PedId: this.PedidosCli.pedId,
                ProId: element.proId,
                PdiNomeProduto: element.pdiNomeProduto,
                PdiQtdeProduto: parseInt(element.pdiQtdeProduto),
                PdiValorProduto: parseFloat(element.pdiValorProduto),
                PdiValorTotalProduto: (element.pdiQtdeProduto*parseFloat(element.pdiValorProduto)),
                PdiDataCadastro: element.pdiDataCadastro
              }

              console.log('pedItem')
              console.log(pedItem)
              axios.put(`/Process/PedPedidoItem/Update/`, pedItem)
                .then(response => {
                    if (response.status == 200) {
                      console.log('OK')
                    }
                });
            });

            if (this.PedidosCli.pedValorCupom==""||this.PedidosCli.pedValorCupom==null){
                this.PedidosCli.pedValorCupom = 0;
            }
            if (this.PedidosCli.pedValorFrete==""||this.PedidosCli.pedValorFrete==null){
                this.PedidosCli.pedValorFrete = 0;
            }
            this.PedidosCli.pedValorCupom = parseFloat(this.PedidosCli.pedValorCupom);
            this.PedidosCli.pedValorFrete = parseFloat(this.PedidosCli.pedValorFrete);
           await axios.put(`/Process/PedPedidos/Update/`, this.PedidosCli)
                .then(response => {
                    if (response.status == 200) {
                      console.log('OK')
                    }
                });


            this.$mensagemAvisoSucesso("Valores Salvos.")
            this.Get();
           
        }
    },
    created() {
        this.Get();

        axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                .then(response => {
                    this.UsuariosVend = response.data.filter(x=> x.cusId != 2);
                    console.log('this.UsuariosVend')
                    console.log(this.UsuariosVend)            
                });

        axios.get('/Process/ConConfigFormasPagamento/GetById/4')
        .then(response => {
            this.logintopo = response.data;
            console.log('this.logintopo')
            console.log(this.logintopo)

        })
        .catch(error => {
            console.error(error);
        });
    },
}
</script>

<style scoped>.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}


td {
    border: 1px solid #333333;
    padding: 3px;
}

</style>
