<template>
    <div>
        
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div v-if="tipoUser!= '' && tipoUser!=null && tipoUser[0].admTipoUsuario!='' &&  tipoUser[0].admTipoUsuario.tusId != 3 && tipoUser[0].admTipoUsuario.tusId != 4">
                            <table>
                                <tr class="semana">
                                <td style="padding: 5px;width: 300px;" v-if="Usuarios!=''">
                                    <v-select v-model="usuarioSelected" label="Vendedores" @update:modelValue="SelecionaVendedor(usuarioSelected)"
                                        :items="Usuarios" item-title="cusNome"
                                        item-value="cusId" variant="underlined">
                                    </v-select>
                                </td>
                                <td style="padding: 5px;">
                                    <button type="button" class="btn btn-warning" @click="SelecionaVendedor(0)">
                                    Todos
                                    </button>
                                </td>
                                </tr>
                            </table>
                        </div>
                        <div class="faq-wrap">
                            <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                                required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <easy-data-table :headers="headers" :items="ListPedidosGeral" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-pedDataPedido="item">
                                        <span> {{ item.pedDataPedido !== null ? formattedDate(item.pedDataPedido) : null }}</span>
                                    </template>
                                    <template #item-diaEntrega="item">
                                        <span> {{ item.diaEntrega !== null ? formattedDate(item.diaEntrega) : null }}</span>
                                    </template>
                                    <template #item-pedUser="item">
                                        <span> {{ item.pedUser!=0 ? Usuarios!==null ? Usuarios.filter(x=> x.cusId == item.pedUser)[0].cusNome : null : null }}</span>
                                    </template>
                                    <template #item-pedValorTotalPedido="item">
                                        {{ item.pedValorTotalPedido !== null ? formatarValor(item.pedValorTotalPedido) : 0 }}
                                    </template>
                                    <template #item-pedObsLoja="item">
                                        {{ item.pedObsLoja !== "" ? item.pedObsLoja : 'Não' }}
                                    </template>
                                    <template #item-pedSituacaoPedido="item">
                                        <button v-if="item.pedSituacaoPedido=='Aguardando'" class="btn btn-warning">{{ item.pedSituacaoPedido }}</button>
                                        <button v-if="item.pedSituacaoPedido=='Aprovado'" class="btn btn-info">{{ item.pedSituacaoPedido }}</button>
                                        <button v-if="item.pedSituacaoPedido=='Preparação' || item.pedSituacaoPedido=='Enviado'" class="btn btn-default">{{ item.pedSituacaoPedido }}</button>
                                        <button v-if="item.pedSituacaoPedido=='Entregue'" class="btn btn-success">{{ item.pedSituacaoPedido }}</button>
                                        <button v-if="item.pedSituacaoPedido=='Cancelado'" class="btn btn-danger">{{ item.pedSituacaoPedido }}</button>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon>mdi-folder-open</v-icon>
                                            <v-tooltip activator="parent" location="top">Detalhes</v-tooltip>
                                        </v-btn>

                                   </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'

export default {
    name: 'ListaPedidosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        searchValue: '',
        NomeTela:'Pedidos',
        InfoTela:'Lista de Pedidos',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 28, cdtNome: "Lista Pedidos" }],
        Pedidos:[],
        ListPedidosGeral:[],
        Usuarios:[],
        usuarioSelected: '',
        valortela:[],
        tipoUser:[],
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        SelecionaVendedor(value){
            if (value != 0){            
                this.ListPedidosGeralFiltro = this.ListPedidosGeral.filter(x=> x.pedUser == value);
            }
            else{
                this.ListPedidosGeralFiltro = this.ListPedidosGeral;
                this.usuarioSelected = null;
            }
           
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                proOrdem: parseInt(item.proOrdem),
            };
            });
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        EditGrid(item) {
            this.$router.push('/pedidodetalhe/' + item.pedId);
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "pedId", sortable: true, });
            this.headers.push({ text: "Data Pedido", value: "pedDataPedido", sortable: true, });
            this.headers.push({ text: "Vendedor", value: "pedUser", sortable: true, });
            this.headers.push({ text: "Cliente", value: "cliNome", sortable: true, });
            this.headers.push({ text: "Frete", value: "pedTipoFrete", sortable: true, });
            this.headers.push({ text: "Transporte", value: "pedObsLoja", sortable: true, });
            this.headers.push({ text: "Forma Pgto", value: "pedFormaPgto", sortable: true, });
            this.headers.push({ text: "Valor", value: "pedValorTotalPedido", sortable: true, });
            this.headers.push({ text: "Situação", value: "pedSituacaoPedido", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Pedidos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }

                    const valorArmazenado = localStorage.getItem('_userav');
                    let valorArmazenado2 = JSON.parse(valorArmazenado);
                    this.valortela = valorArmazenado2.menu.filter(x=> x.cdtId == 28);
                    this.tipoUser = this.Usuarios.filter(x=> x.cusId == this.valortela[0].cusId);

                    axios.get(`/Process/PedPedidos/GetAllValorTotal`) //GetAllByCtiId/14
                        .then(response => {
                            this.ListPedidosGeral = response.data.filter(x => x.idiId == 1 && x.pedSituacaoPedido == "Entregue" || x.pedSituacaoPedido == "Cancelado");

                            if (this.tipoUser[0].admTipoUsuario.tusId == 3 || this.tipoUser[0].admTipoUsuario.tusId == 4){
                                this.ListPedidosGeral = this.ListPedidosGeral.filter(x=> x.pedUser == this.valortela[0].cusId)
                            }   

                            this.ListPedidosGeral = this.ListPedidosGeral.sort((a, b) => {
                                                                                const valorA = parseInt(a.pedId) || 0;
                                                                                const valorB = parseInt(b.pedId) || 0;
                                                                                return valorA - valorB;
                                                                            }).reverse();

                            console.log('this.ListPedidosGeral')   
                            console.log(this.ListPedidosGeral)   
                        });
                    this.montaGrid();
                    this.Pedidos.cdtId = 28;
                    this.Pedidos.grtId = 7;

                    console.log('this.Pedidos')    
                    console.log(this.Pedidos)         


                });
        }
    },
    async created() {
        await axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                        .then(response => {
                            this.Usuarios = response.data.filter(x=> x.cusAtivo);
                            console.log('this.Usuarios')
                            console.log(this.Usuarios)
                        });
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
